import React, { PureComponent } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarMail from './TopbarMail';
// import TopbarNotification from './TopbarNotification';
// import TopbarSearch from './TopbarSearch';
import { UserProps } from '../../../shared/prop-types/ReducerProps';


class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    user: UserProps.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <h4 style={{ display: "grid", alignItems: "center", fontWeight:"bold", color: "#70bbfd" }} > Azul Refrigeración</h4>
          </div>
          <div className="topbar__right">
            {/* <TopbarSearch /> */}
            {/* <TopbarNotification />
            <TopbarMail new /> */}
            <TopbarProfile user={localStorage.getItem('username')} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
