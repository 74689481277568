import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logo from '../../../shared/img/logo/logo_pdf.png';
import FontBold from '../../../shared/fonts/Roboto-Bold.ttf';
import FontRegular from '../../../shared/fonts/Roboto-Regular.ttf';
import Moment from 'moment';

Font.register({ family: 'Roboto-Bold', src: FontBold });
Font.register({ family: 'Roboto-Regular', src: FontRegular });

let ot, customer, telephone, cellular;

const styles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol20: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol25: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol50: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol100: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol100Separator: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontSize: 16
    },
    tableCell: {
        // margin: "auto",
        fontFamily: 'Roboto-Regular',
        fontWeight: "light",
        marginTop: 2,
        fontSize: 9,
        textAlign: "left"
    },
    tableCellBold: {
        // margin: "auto",
        fontFamily: 'Roboto-Bold',
        marginTop: 2,
        fontSize: 9,
        textAlign: "left",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    tableFooter: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        padding: 2,
    },
    tableRowFooter: {
        margin: "auto",
        flexDirection: "row",
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableColFooter: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol30Footer: {
        width: "30%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol35Footer: {
        width: "35%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol20Footer: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol85Footer: {
        width: "85%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCol15Footer: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingRight: 5,
    },
    tableCellFooter: {
        fontFamily: 'Roboto-Regular',
        fontWeight: "light",
        marginTop: 2,
        fontSize: 8,
        textAlign: "left",
    },
    tableCellFooterBold: {
        fontFamily: 'Roboto-Bold',
        fontWeight: "light",
        marginTop: 2,
        fontSize: 8,
        textAlign: "left",
        textTransform: 'uppercase',
    },
    tableCol100Footer: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        marginTop: 10,
        minHeight: '100%',
        display: 'block',
        height: '30%',
        width: '30%',
        opacity: 1
    },
    pageBackgroundTwo: {
        position: 'absolute',
        marginTop: 385,
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '30%',
        width: '30%',
        opacity: 1
    }
});

const OtPdf = (props) => (
    ot = props.ot,
    customer = props.customer,
    telephone = props.customer.phones.filter( (phone) => { return !phone.isMobile } ),
    cellular = props.customer.phones.filter( (phone) => { return phone.isMobile } ),

    <Document>
        <Page size="A4" style={styles.page}>

            {/* LOGO */}
            <View style={styles.pageBackground}>
                <Image style={{ opacity: '0.3' }} src={logo} alt="logo" />
            </View>

            {/* TITULO */}
            <View style={{ width: "100%", justifyContent: "center", alignItems: "center", padding: "5px 20px 0px 20px", fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                <View style={styles.tableRow}>
                    <Text styles={{ textAlign: 'center', fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>Azul Refrigeración </Text>
                    <Image style={{ width: '13px', height: '10px' }} src={logo} alt="Logo Miniatura" />
                </View>
            </View>

            {/* CLIENTE */}
            <View style={{ justifyContent: 'space-between', flexDirection: "row", width: "100%", padding: '0px, 20px, 0px, 20px', fontFamily: 'Roboto-Bold', fontSize: 9 }}>
                <View style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                    <Text style={{ textAlign: 'left', fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>DATOS DEL CLIENTE</Text>
                </View>
                <View style={{ alignSelf: 'center', position: 'absolute', left: '20', right: '20', alignItems: 'center', fontSize: 9 }}>
                    <View style-={{ fontFamily: 'Roboto-Bold' }}>
                        <Text style={{ fontSize: 8 }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: 'heavy' }}>Orden de Trabajo No. </Text><Text style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy' }}>{ot.id}</Text></Text>
                    </View>
                </View>
                <View style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                    <Text style={{ fontSize: 9 }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: 'heavy' }}>Alvear <Text style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy' }}>44</Text> / Tel <Text style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy' }}>4216243</Text> / Salta </Text></Text>
                </View>
            </View>

            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                    margin: "0px 20px 0px 20px"
                }}
            />
            <View style={{ padding: "5px 20px 5px 20px" }}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Nombre Completo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Domicilio</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Fecha de Ingreso</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.customer && ot.customer.fullName ? ot.customer.fullName : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.address ? ot.address : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}>{ot && ot.beginDate ? Moment(ot.beginDate).format("DD-MM-YYYY") : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Tipo de Cliente</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Barrio</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Fecha de Salida</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.customerType ? ot.customerType : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.customer && ot.customer.neighbourhood ? ot.customer.neighbourhood : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}>{ot && ot.endDate ? Moment(ot.endDate).format("DD-MM-YYYY") : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Motivo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Teléfono</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Cód. Autorización</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.motive ? ot.motive : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{customer && telephone[0] && telephone[0].number ? telephone[0].number : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}>{ot && ot.authorizationCode ? ot.authorizationCode : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Trabajo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Celular</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Cód. Externo</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{customer && cellular[0] && cellular[0].number ? cellular[0].number : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}></Text>
                        </View>
                    </View>
                </View>
            </View>

            {/* PRODUCTO */}
            <View style={{ padding: "0px 20px 0px 20px", fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                <Text styles={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>{'DATOS DEL PRODUCTO'}</Text>
            </View>

            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                    margin: "0px 20px 0px 20px",
                }}
            />

            <View style={{ padding: "5px 20px 0px 20px" }}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol25}>
                            <Text style={styles.bold, styles.tableCell}>Producto</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCell}>Modelo</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCell}>Marca</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCell}>No. de serie</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.type && ot.product.type.name ? ot.product.type.name : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.model ? ot.product.model.name : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.model && ot.product.model.brand ? ot.product.model.brand.name : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.serialNumber && ot.product.serialNumber ? ot.product.serialNumber : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCell}>Falla</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCellBold}>{ot && ot.failureDescription ? ot.failureDescription : 'N/A'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCell}>Accesorios recibidos</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCellBold}>{ot && ot.recievedParts ? ot.recievedParts : 'N/A'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={{ alignItems: 'start', flexDirection: 'row', width: "100%", borderStyle: "solid", borderWidth: 0, borderLeftWidth: 0, borderTopWidth: 0, paddingTop: '5px', paddingBottom: '5px' }}>
                            <Text style={{ alignSelf: 'center', fontFamily: 'Roboto-Regular', fontWeight: "light", fontSize: 9, textAlign: "left", paddingRight: '10px' }}>Son pesos</Text>
                            <View style={{ width: '300px', flexDirection: 'row', backgroundColor: '#ccc', borderRadius: '25', padding: '5px', marginRight: '5px' }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>  </Text><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>  </Text></View>
                            <View style={{ width: '30%', flexDirection: 'row', backgroundColor: '#ccc', borderRadius: '25', padding: '5px' }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>$ </Text><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>{ot && ot.budget ? ot.budget : ''}</Text></View>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "light", margin: '5px 0px 3px 0px', fontSize: 9, textAlign: "left", }}>{'Observaciones _________________________________________________________________________________________________________________________'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "light", margin: '3px 0px', fontSize: 9, textAlign: "left", }}>{'________________________________________________________________________________________________________________________________________'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={{ width: "100%", borderStyle: "solid", borderWidth: 0, borderLeftWidth: 0, borderTopWidth: 0, paddingTop: '0px', paddingBottom: '10px' }}>
                            <Text style={styles.tableCell}>{'Los trabajos deberán ser retirados antes de los 60 (sesenta) días de finalizada su ejecución. Caso contrario. La Firma podrá disponer de los mismos. Vencido este plazo el cliente deberá abonar la estadía. Consulte el estado de su producto ingresando el No. de orden en www.azulrefrigeracion.com.ar.'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCell}>{'Firma conforme del cliente_________________________________________________ Aclaración__________________________________________________'}</Text>
                        </View>
                    </View>


                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCol100Separator}>{'• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •'}</Text>
                        </View>
                    </View>
                </View>
            </View>

            {/* LOGO */}
            <View style={styles.pageBackgroundTwo}>
                <Image style={{ opacity: '0.3' }} src={logo} alt="logo" />
            </View>

            {/* TITULO */}
            <View style={{ width: "100%", justifyContent: "center", alignItems: "center", padding: "0px 20px 0px 20px", fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                <View style={styles.tableRow}>
                    <Text styles={{ textAlign: 'center', fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>Azul Refrigeración </Text>
                    <Image style={{ width: '13px', height: '10px' }} src={logo} alt="Logo Miniatura" />
                </View>
            </View>

            {/* CLIENTE */}
            <View style={{ justifyContent: 'space-between', flexDirection: "row", width: "100%", padding: '0px, 20px, 0px, 20px', fontFamily: 'Roboto-Bold', fontSize: 9 }}>
                <View style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                    <Text style={{ textAlign: 'left', fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>DATOS DEL CLIENTE</Text>
                </View>
                <View style={{ alignSelf: 'center', position: 'absolute', left: '20', right: '20', alignItems: 'center', fontSize: 9 }}>
                    <View style-={{ fontFamily: 'Roboto-Bold' }}>
                        <Text style={{ fontSize: 8 }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: 'heavy' }}>Orden de Trabajo No. </Text><Text style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy' }}>{ot.id}</Text></Text>
                    </View>
                </View>
                <View style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                    <Text style={{ fontSize: 9 }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: 'heavy' }}>Alvear <Text style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy' }}>44</Text> / Tel <Text style={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy' }}>4216243</Text> / Salta </Text></Text>
                </View>
            </View>

            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                    margin: "0px 20px 0px 20px"
                }}
            />
            <View style={{ padding: "5px 20px 5px 20px" }}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Nombre Completo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Domicilio</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Fecha de Ingreso</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.customer && ot.customer.fullName ? ot.customer.fullName : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.address ? ot.address : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}>{ot && ot.beginDate ? Moment(ot.beginDate).format("DD-MM-YYYY") : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Tipo de Cliente</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Barrio</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Fecha de Salida</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.customerType ? ot.customerType : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.customer && ot.customer.neighbourhood ? ot.customer.neighbourhood  : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}>{ot && ot.endDate ? Moment(ot.endDate).format("DD-MM-YYYY") : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Motivo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Teléfono</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Cód. Autorización</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{ot && ot.motive ? ot.motive : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{customer && telephone[0] && telephone[0].number ? telephone[0].number : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}>{ot && ot.authorizationCode ? ot.authorizationCode : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Trabajo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Celular</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCell}>Cód. Externo</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellBold}>{customer && cellular[0] && cellular[0].number ? cellular[0].number : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol20}>
                            <Text style={styles.tableCellBold}></Text>
                        </View>
                    </View>
                </View>
            </View>

            {/* PRODUCTO */}
            <View style={{ padding: "0px 20px 0px 20px", fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>
                <Text styles={{ fontFamily: 'Roboto-Bold', fontWeight: 'heavy', fontSize: 9 }}>{'DATOS DEL PRODUCTO'}</Text>
            </View>

            <View
                style={{
                    borderBottomColor: 'black',
                    borderBottomWidth: 1,
                    margin: "0px 20px 0px 20px",
                }}
            />

            <View style={{ padding: "5px 20px 0px 20px" }}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol25}>
                            <Text style={styles.bold, styles.tableCell}>Producto</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCell}>Modelo</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCell}>Marca</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCell}>No. de serie</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.type && ot.product.type.name ? ot.product.type.name : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.model ? ot.product.model.name : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.model && ot.product.model.brand ? ot.product.model.brand.name : 'N/A'}</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellBold}>{ot && ot.product && ot.product.serialNumber && ot.product.serialNumber ? ot.product.serialNumber : 'N/A'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCell}>Falla</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCellBold}>{ot && ot.failureDescription ? ot.failureDescription : 'N/A'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCell}>Accesorios recibidos</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCellBold}>{ot && ot.recievedParts ? ot.recievedParts : 'N/A'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={{ alignItems: 'start', flexDirection: 'row', width: "100%", borderStyle: "solid", borderWidth: 0, borderLeftWidth: 0, borderTopWidth: 0, paddingTop: '5px', paddingBottom: '5px' }}>
                            <Text style={{ alignSelf: 'center', fontFamily: 'Roboto-Regular', fontWeight: "light", fontSize: 9, textAlign: "left", paddingRight: '10px' }}>Son pesos</Text>
                            <View style={{ width: '300px', flexDirection: 'row', backgroundColor: '#ccc', borderRadius: '25', padding: '5px', marginRight: '5px' }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>  </Text><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>  </Text></View>
                            <View style={{ width: '30%', flexDirection: 'row', backgroundColor: '#ccc', borderRadius: '25', padding: '5px' }}><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>$ </Text><Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "heavy", fontSize: 9, textAlign: "left" }}>{ot && ot.budget ? ot.budget : ''}</Text></View>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "light", margin: '5px 0px 3px 0px', fontSize: 9, textAlign: "left", }}>{'Observaciones _________________________________________________________________________________________________________________________'}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={{ fontFamily: 'Roboto-Regular', fontWeight: "light", margin: '3px 0px', fontSize: 9, textAlign: "left", }}>{'________________________________________________________________________________________________________________________________________'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={{ width: "100%", borderStyle: "solid", borderWidth: 0, borderLeftWidth: 0, borderTopWidth: 0, paddingTop: '0px', paddingBottom: '10px' }}>
                            <Text style={styles.tableCell}>{'Los trabajos deberán ser retirados antes de los 60 (sesenta) días de finalizada su ejecución. Caso contrario. La Firma podrá disponer de los mismos. Vencido este plazo el cliente deberá abonar la estadía. Consulte el estado de su producto ingresando el No. de orden en www.azulrefrigeracion.com.ar.'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCell}>{'Firma conforme del cliente_________________________________________________ Aclaración__________________________________________________'}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol100}>
                            <Text style={styles.tableCol100Separator}>{'• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •'}</Text>
                        </View>
                    </View>
                </View>
            </View>

            {/* MEMBRETE P/ TECNICO */}
            <View style={{ padding: "2px", borderStyle: "solid", borderWidth: 1, margin: '0px, 20px' }}>
                <View style={styles.tableFooter}>
                    <View style={styles.tableRowFooter}>
                        <View style={styles.tableCol35Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Cliente </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.customer && ot.customer.fullName ? ot.customer.fullName : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol30Footer}>
                            <Text><Text style={styles.tableCellFooter}>Producto </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.product && ot.product.type && ot.product.type.name ? ot.product.type.name : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol20Footer}>
                        </View>
                        <View style={styles.tableCol15Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>No. </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.id ? ot.id : 'N/A'}</Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.tableRowFooter}>
                        <View style={styles.tableCol35Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Tipo de Cliente </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.customerType ? ot.customerType : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol20Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Marca </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.product && ot.product.model && ot.product.model.brand ? ot.product.model.brand.name : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol30Footer}>
                        </View>
                        <View style={styles.tableCol15Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Fecha de Ingreso</Text>
                                <Text style={styles.tableCellFooterBold}></Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.tableRowFooter}>
                        <View style={styles.tableCol35Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Celular </Text>
                                <Text style={styles.tableCellFooterBold}>{customer && cellular[0] ? cellular[0].number : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol20Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Modelo </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.product && ot.product.model ? ot.product.model.name: 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol30Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}></Text>
                                <Text style={styles.tableCellFooterBold}></Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol15Footer}>
                            <Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.beginDate ? Moment(ot.beginDate).format("DD/MM/YYYY") : 'N/A'}</Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.tableRowFooter}>
                        <View style={styles.tableCol35Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Teléfono </Text>
                                <Text style={styles.tableCellFooterBold}>{customer && telephone[0] ? telephone[0].number : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol20Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Cód. Autorización </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.authorizationCode ? ot.authorizationCode : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableCol30Footer}/>
                        <View style={styles.tableCol15Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Fecha de Salida</Text>
                                <Text style={styles.tableCellFooterBold}></Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.tableRowFooter}>
                        <View style={styles.tableCol85Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Domicilio </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.address ? ot.address : 'N/A'}</Text>
                            </Text>
                        </View>
                        <View style={styles.tableColFooter}>
                            <Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.endDate ? Moment(ot.endDate).format("DD/MM/YYYY") : 'N/A'}</Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.tableRowFooter}>
                        <View style={styles.tableCol100Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Falla </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.failureDescription ? ot.failureDescription : 'N/A'}</Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.tableRowFooter}>
                        <View style={styles.tableCol100Footer}>
                            <Text>
                                <Text style={styles.tableCellFooter}>Accesorios recibidos  </Text>
                                <Text style={styles.tableCellFooterBold}>{ot && ot.recievedParts ? ot.recievedParts : 'N/A'}</Text>
                            </Text>
                        </View>
                    </View>

                </View>
            </View>
        </Page>
    </Document >
);

export default OtPdf;
