var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Input, Form, Alert, Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import { Checkbox } from '@material-ui/core';
import { Creatable } from 'react-select';
import ReactLoading from 'react-loading';
function getEmptyProduct() {
    return {
        serialNumber: '',
        purchaseDate: '',
        state: '',
        model: null,
        modelLabel: 'N/A',
        typeId: 0,
        typeName: '',
        diagnostic: {}
    };
}
var otFormErrorMsg = "Hubo un error en el servidor";
var OtForm = /** @class */ (function (_super) {
    __extends(OtForm, _super);
    function OtForm(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            product: getEmptyProduct(),
            brands: [],
            models: [],
            brandId: null,
            brandLabel: 'N/A',
            productTypes: [],
            customers: [],
            isPurchaseDateValid: false,
            isBeginDateValid: false,
            isEndDateValid: false,
            hasAuthorizationCode: false,
            otStates: [],
            customerTypes: [],
            employees: [],
            address: '',
            customerId: 0,
            customerTypeId: 0,
            motiveId: 0,
            motives: [],
            employeeId: 0,
            otStateId: 0,
            budget: 0,
            errorDescription: '',
            recievedParts: '',
            beginDate: (new Date(Date.now())).toISOString(),
            endDate: '',
            authorizationCode: '',
            errorMessage: '',
            isLoading: false
        };
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        _this.handleErrorDescriptionChange = _this.handleErrorDescriptionChange.bind(_this);
        _this.handleBudgetChange = _this.handleBudgetChange.bind(_this);
        _this.handleRecievedPartsChange = _this.handleRecievedPartsChange.bind(_this);
        _this.handleCustomerChange = _this.handleCustomerChange.bind(_this);
        _this.handleStateChange = _this.handleStateChange.bind(_this);
        _this.handleEmployeeChange = _this.handleEmployeeChange.bind(_this);
        _this.handleSerialNumberChange = _this.handleSerialNumberChange.bind(_this);
        _this.handleBrandChange = _this.handleBrandChange.bind(_this);
        _this.handleProductModelChange = _this.handleProductModelChange.bind(_this);
        _this.handlePurchaseDateChange = _this.handlePurchaseDateChange.bind(_this);
        _this.handleBeginDateChange = _this.handleBeginDateChange.bind(_this);
        _this.handleEndDateChange = _this.handleEndDateChange.bind(_this);
        _this.handleProductStateChange = _this.handleProductStateChange.bind(_this);
        _this.handleProductTypeChange = _this.handleProductTypeChange.bind(_this);
        _this.handleDiagnosticChange = _this.handleDiagnosticChange.bind(_this);
        _this.handleAddressChange = _this.handleAddressChange.bind(_this);
        _this.handleCreateBrand = _this.handleCreateBrand.bind(_this);
        _this.handleCreateModel = _this.handleCreateModel.bind(_this);
        _this.verifyOrCreateEmptyModel = _this.verifyOrCreateEmptyModel.bind(_this);
        _this.handleCustomerTypeChange = _this.handleCustomerTypeChange.bind(_this);
        _this.handleCheckAuthorizationChange = _this.handleCheckAuthorizationChange.bind(_this);
        _this.handleAuthorizationCodeChange = _this.handleAuthorizationCodeChange.bind(_this);
        _this.handleMotiveChange = _this.handleMotiveChange.bind(_this);
        return _this;
    }
    OtForm.prototype.getModelByBrand = function (brandId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/model/bybrand/" + brandId)
                            .then(function (res) {
                            _this.setState({ models: res.data });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.getBrands = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/brand")
                            .then(function (res) {
                            _this.setState({ brands: res.data });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var product;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            customers: this.props.customers,
                            isLoading: true
                        });
                        return [4 /*yield*/, this.loadDropdownOptions()];
                    case 1:
                        _a.sent();
                        if (!this.props.otId) return [3 /*break*/, 3];
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/ot/" + this.props.otId)
                                .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                                var product, brandId, brandLabel, employeeId, motiveId, customerTypeId;
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
                                return __generator(this, function (_y) {
                                    switch (_y.label) {
                                        case 0:
                                            product = Object.assign(getEmptyProduct(), res.data.product);
                                            product.diagnostic.description = (_a = res.data.diagnostic) === null || _a === void 0 ? void 0 : _a.description;
                                            if (this.state.productTypes.find(function (type) { var _a; return (type === null || type === void 0 ? void 0 : type.id) === ((_a = res.data.product.type) === null || _a === void 0 ? void 0 : _a.id); })) {
                                                product.typeId = (_b = res.data.product.type) === null || _b === void 0 ? void 0 : _b.id;
                                                product.typeName = (_c = res.data.product.type) === null || _c === void 0 ? void 0 : _c.name;
                                            }
                                            else if (this.state.productTypes.length > 0) {
                                                product.typeId = this.state.productTypes[0].id;
                                                product.typeName = this.state.productTypes[0].name;
                                            }
                                            brandId = (_f = (_e = (_d = res.data.product) === null || _d === void 0 ? void 0 : _d.model) === null || _e === void 0 ? void 0 : _e.brand) === null || _f === void 0 ? void 0 : _f.id;
                                            brandLabel = ((_j = (_h = (_g = res.data.product) === null || _g === void 0 ? void 0 : _g.model) === null || _h === void 0 ? void 0 : _h.brand) === null || _j === void 0 ? void 0 : _j.name) || 'N/A';
                                            return [4 /*yield*/, this.getModelByBrand(brandId)];
                                        case 1:
                                            _y.sent();
                                            product.model = (_m = (_l = (_k = res.data.product) === null || _k === void 0 ? void 0 : _k.model) === null || _l === void 0 ? void 0 : _l.id) !== null && _m !== void 0 ? _m : (_o = this.state.models[0]) === null || _o === void 0 ? void 0 : _o.id;
                                            product.modelLabel = (_t = (_r = (_q = (_p = res.data.product) === null || _p === void 0 ? void 0 : _p.model) === null || _q === void 0 ? void 0 : _q.name) !== null && _r !== void 0 ? _r : (_s = this.state.models[0]) === null || _s === void 0 ? void 0 : _s.name) !== null && _t !== void 0 ? _t : 'N/A';
                                            employeeId = !res.data.employees[0] ? 0 : res.data.employees[0].id;
                                            motiveId = ((_u = res.data.motive) === null || _u === void 0 ? void 0 : _u.id) ? (_v = res.data.motive) === null || _v === void 0 ? void 0 : _v.id : this.state.motives.length > 0 ? this.state.motives[0].id : 0;
                                            customerTypeId = ((_w = res.data.customerType) === null || _w === void 0 ? void 0 : _w.id) ? (_x = res.data.customerType) === null || _x === void 0 ? void 0 : _x.id : this.state.customers.length > 0 ? this.state.customers[0].id : 0;
                                            this.setState({
                                                product: product,
                                                customerId: res.data.customer.id,
                                                motiveId: motiveId,
                                                customerTypeId: customerTypeId,
                                                brandId: brandId,
                                                brandLabel: brandLabel,
                                                employeeId: employeeId,
                                                otStateId: res.data.state.id,
                                                budget: parseInt(res.data.budget),
                                                errorDescription: res.data.failureDescription,
                                                recievedParts: res.data.recievedParts,
                                                beginDate: res.data.beginDate,
                                                endDate: res.data.endDate,
                                                address: res.data.address,
                                                authorizationCode: res.data.authorizationCode,
                                                hasAuthorizationCode: res.data.authorizationCode != null && res.data.authorizationCode.trim() != ""
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        if (this.state.productTypes.length > 0) {
                            product = Object.assign(getEmptyProduct(), this.state.product);
                            product.typeId = this.state.productTypes[0].id;
                            product.typeName = this.state.productTypes[0].name;
                            this.setState({
                                product: product,
                            });
                        }
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/customer/" + this.state.customerId).then(function (res) {
                                var customer = res.data;
                                _this.setState({
                                    address: customer.addresses[0].address,
                                });
                            }).catch(function (err) {
                                console.log(err);
                                toast.error(otFormErrorMsg);
                                _this.setState({
                                    address: "",
                                });
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.setState({ isLoading: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.loadDropdownOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/product/types")
                                .then(function (res) {
                                _this.setState({ productTypes: res.data });
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getBrands()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/ot/states")
                                .then(function (res) {
                                _this.setState({
                                    otStates: res.data.map(function (state) {
                                        return {
                                            state: state.state,
                                            id: state.id,
                                        };
                                    })
                                });
                            })];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/ot/customerType")
                                .then(function (res) {
                                _this.setState({
                                    customerTypes: res.data.map(function (type) {
                                        return {
                                            name: type.name,
                                            id: type.id,
                                        };
                                    })
                                });
                            })];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/ot/motives")
                                .then(function (res) {
                                _this.setState({
                                    motives: res.data.map(function (type) {
                                        return {
                                            name: type.name,
                                            id: type.id,
                                        };
                                    })
                                });
                            })];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/employee")
                                .then(function (res) {
                                _this.setState({
                                    employees: res.data.map(function (employee) {
                                        return {
                                            fullName: employee.fullName,
                                            id: employee.id,
                                        };
                                    })
                                });
                            })];
                    case 6:
                        _a.sent();
                        this.setState({
                            employeeId: this.state.employees.length > 0 ? this.state.employees[0].id : 0,
                            customerId: this.state.customers.length > 0 ? this.state.customers[0].id : 0,
                            otStateId: this.state.otStates.length > 0 ? this.state.otStates[0].id : 0,
                            customerTypeId: this.state.customerTypes.length > 0 ? this.state.customerTypes[0].id : 0,
                            motiveId: this.state.motives.length > 0 ? this.state.motives[0].id : 0,
                        });
                        return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        toast.error(otFormErrorMsg);
                        console.error(err_1);
                        throw new Error(err_1);
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.handleSerialNumberChange = function (event) {
        event.preventDefault();
        var product = Object.assign(getEmptyProduct(), this.state.product);
        product.serialNumber = event.target.value;
        this.setState({
            product: product,
        });
    };
    OtForm.prototype.handleCreateModel = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/model", { brandId: this.state.brandId, name: event })
                            .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                            var model, product;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.getModelByBrand(this.state.brandId)];
                                    case 1:
                                        _a.sent();
                                        model = this.state.models.find(function (model) {
                                            return model.name == event;
                                        });
                                        product = Object.assign(getEmptyProduct(), this.state.product);
                                        product.model = model === null || model === void 0 ? void 0 : model.id;
                                        product.modelLabel = model === null || model === void 0 ? void 0 : model.name;
                                        this.setState({
                                            product: product
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); }).catch(function (err) {
                            console.log(err);
                            toast.error(otFormErrorMsg);
                            var product = Object.assign(getEmptyProduct(), _this.state.product);
                            product.model = 0;
                            product.modelLabel = 'N/A';
                            _this.setState({
                                product: product
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.handleCreateBrand = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/brand", { name: event })
                            .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                            var brandId;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.getBrands()];
                                    case 1:
                                        _a.sent();
                                        brandId = this.state.brands.find(function (brand) {
                                            return brand.name == event;
                                        });
                                        this.setState({
                                            brandId: brandId === null || brandId === void 0 ? void 0 : brandId.id,
                                            brandLabel: event
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); }).catch(function (err) {
                            console.log(err);
                            toast.error(otFormErrorMsg);
                            _this.setState({
                                brandId: 0,
                                brandLabel: 'N/A'
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.handleBrandChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var product, brandId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        product = Object.assign(getEmptyProduct(), this.state.product);
                        product.model = null;
                        product.modelLabel = 'N/A';
                        if (!event) return [3 /*break*/, 2];
                        brandId = parseInt(event.value);
                        return [4 /*yield*/, this.getModelByBrand(brandId)];
                    case 1:
                        _a.sent();
                        this.setState({
                            brandId: brandId,
                            brandLabel: event.label,
                            product: product
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        this.setState({
                            brandId: null,
                            brandLabel: 'N/A',
                            models: [],
                            product: product
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.handleProductModelChange = function (event) {
        if (event) {
            var product = Object.assign(getEmptyProduct(), this.state.product);
            product.model = parseInt(event.value);
            product.modelLabel = event.label;
            this.setState({
                product: product,
            });
        }
        else {
            var product = Object.assign(getEmptyProduct(), this.state.product);
            product.model = 0;
            product.modelLabel = 'N/A';
            this.setState({
                product: product
            });
        }
    };
    OtForm.prototype.handleBeginDateChange = function (event) {
        var _a, _b;
        event.preventDefault();
        var target = event.target;
        var value = (new Date(target.value)).toISOString();
        var onlyDate = value.split('T')[0];
        //TODO: Remove hardcoding
        var onlyTimeBeginState = (_b = (_a = this.state.beginDate) === null || _a === void 0 ? void 0 : _a.split('T')[1]) !== null && _b !== void 0 ? _b : "03:00:00.000Z";
        var dateFormat = onlyDate + "T" + onlyTimeBeginState;
        var dateNow = new Date();
        var isFuture = dateNow.getTime() > new Date(dateFormat).getTime();
        this.setState({
            beginDate: dateFormat,
            isBeginDateValid: !isFuture,
        });
    };
    OtForm.prototype.handleEndDateChange = function (event) {
        var _a, _b;
        event.preventDefault();
        var target = event.target;
        var value = target.value ? (new Date(target.value)).toISOString() : "";
        var onlyDate = value.split('T')[0];
        //TODO: Remove hardcoding
        var onlyTimeEndState = (_b = (_a = this.state.endDate) === null || _a === void 0 ? void 0 : _a.split('T')[1]) !== null && _b !== void 0 ? _b : "03:00:00.000Z";
        var dateFormat = onlyDate + "T" + onlyTimeEndState;
        var isAfterBeginDate = moment(value) > moment(this.state.beginDate);
        this.setState({
            endDate: dateFormat,
            isEndDateValid: !isAfterBeginDate,
        });
    };
    OtForm.prototype.handlePurchaseDateChange = function (event) {
        event.preventDefault();
        var target = event.target;
        var value = (new Date(target.value)).toISOString();
        var onlyDate = value.split('T')[0];
        var onlyTime = value.split('T')[1];
        var dateFormmat = onlyDate + "T" + onlyTime;
        var dateNow = new Date();
        var isFuture = dateNow.getTime() > new Date(dateFormmat).getTime();
        var product = Object.assign(getEmptyProduct(), this.state.product);
        product.purchaseDate = dateFormmat;
        this.setState({
            product: product,
            isPurchaseDateValid: !isFuture,
        });
    };
    OtForm.prototype.handleProductStateChange = function (event) {
        event.preventDefault();
        var product = Object.assign(getEmptyProduct(), this.state.product);
        product.state = event.target.value;
        this.setState({
            product: product,
        });
    };
    OtForm.prototype.handleProductTypeChange = function (event) {
        event.preventDefault();
        var product = Object.assign(getEmptyProduct(), this.state.product);
        product.typeId = parseInt(event.target.value);
        this.setState({
            product: product,
        });
    };
    OtForm.prototype.handleCustomerTypeChange = function (event) {
        event.preventDefault();
        this.setState({
            customerTypeId: parseInt(event.target.value),
        });
    };
    OtForm.prototype.handleMotiveChange = function (event) {
        event.preventDefault();
        this.setState({
            motiveId: parseInt(event.target.value),
        });
    };
    OtForm.prototype.handleDiagnosticChange = function (event) {
        event.preventDefault();
        var product = Object.assign(getEmptyProduct(), this.state.product);
        product.diagnostic.description = event.target.value;
        this.setState({
            product: product,
        });
    };
    OtForm.prototype.handleBudgetChange = function (event) {
        event.preventDefault();
        this.setState({
            budget: parseInt(event.target.value),
        });
    };
    OtForm.prototype.handleRecievedPartsChange = function (event) {
        event.preventDefault();
        this.setState({
            recievedParts: event.target.value,
        });
    };
    OtForm.prototype.handleAuthorizationCodeChange = function (event) {
        event.preventDefault();
        this.setState({
            authorizationCode: event.target.value,
        });
    };
    OtForm.prototype.handleCheckAuthorizationChange = function (event) {
        event.preventDefault();
        this.setState({
            hasAuthorizationCode: !this.state.hasAuthorizationCode
        });
    };
    OtForm.prototype.handleCustomerChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var customerId;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        customerId = event.target.value;
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/customer/" + event.target.value).then(function (res) {
                                var customer = res.data;
                                _this.setState({
                                    address: customer.addresses[0].address,
                                    customerId: customerId
                                });
                            }).catch(function (err) {
                                console.log(err);
                                toast.error(otFormErrorMsg);
                                _this.setState({
                                    address: "",
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.handleStateChange = function (event) {
        event.preventDefault();
        this.setState({
            otStateId: parseInt(event.target.value),
        });
    };
    OtForm.prototype.handleEmployeeChange = function (event) {
        event.preventDefault();
        this.setState({
            employeeId: parseInt(event.target.value),
        });
    };
    OtForm.prototype.handleErrorDescriptionChange = function (event) {
        event.preventDefault();
        this.setState({
            errorDescription: event.target.value,
        });
    };
    OtForm.prototype.handleAddressChange = function (event) {
        event.preventDefault();
        this.setState({
            address: event.target.value,
        });
    };
    OtForm.prototype.handleSubmit = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var sendData, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        if (!(!this.state.product.model && this.state.brandId)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.verifyOrCreateEmptyModel(this.state.brandId)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        sendData = {
                            customerId: this.state.customerId,
                            customerTypeId: this.state.customerTypeId,
                            motiveId: this.state.motiveId,
                            employeeId: this.state.employeeId,
                            failureDescription: this.state.errorDescription,
                            recievedParts: this.state.recievedParts,
                            product: this.state.product,
                            stateId: this.state.otStateId,
                            budget: this.state.budget,
                            beginDate: this.state.beginDate,
                            endDate: this.state.endDate,
                            address: this.state.address,
                            authorizationCode: this.state.authorizationCode
                        };
                        if (!this.props.otId) return [3 /*break*/, 5];
                        return [4 /*yield*/, axios.put(process.env.REACT_APP_SERVER_URL + "/ot/" + this.props.otId, sendData)];
                    case 4:
                        _a.sent();
                        toast.success("Orden de trabajo modificada exitosamente");
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/ot", sendData)];
                    case 6:
                        _a.sent();
                        toast.success("Orden de trabajo agregada exitosamente");
                        _a.label = 7;
                    case 7:
                        this.props.onClose();
                        return [3 /*break*/, 9];
                    case 8:
                        err_2 = _a.sent();
                        toast.error(otFormErrorMsg);
                        console.log(err_2);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    ;
    OtForm.prototype.verifyOrCreateEmptyModel = function (brandId) {
        return __awaiter(this, void 0, void 0, function () {
            var withoutModel, product, model, product;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        withoutModel = this.state.models.find(function (model) { var _a; return ((_a = model.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == 'sin modelo'; });
                        if (!withoutModel) return [3 /*break*/, 1];
                        product = Object.assign(getEmptyProduct(), this.state.product);
                        product.model = withoutModel.id;
                        product.modelLabel = withoutModel.name;
                        this.setState({
                            product: product,
                        });
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, this.handleCreateModel('Sin modelo')];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getModelByBrand(brandId)];
                    case 3:
                        _a.sent();
                        model = this.state.models.find(function (model) { var _a; return ((_a = model.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == 'sin modelo'; });
                        product = Object.assign(getEmptyProduct(), this.state.product);
                        product.model = model === null || model === void 0 ? void 0 : model.id;
                        product.modelLabel = model === null || model === void 0 ? void 0 : model.name;
                        this.setState({
                            product: product,
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OtForm.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return (this.state.isLoading ? React.createElement("div", { style: {
                position: 'absolute', left: '55%', top: '50%',
                transform: 'translate(-50%, -50%)',
            } },
            React.createElement(ReactLoading, { type: "spinningBubbles", color: "#70bbfd", height: 80, width: 80 })) :
            React.createElement(Modal, { isOpen: this.props.isModalOpen, className: "modal-dialog--primary modal-dialog--header", style: { maxWidth: '70%' } },
                React.createElement(Form, { onSubmit: this.handleSubmit },
                    React.createElement("div", { className: "modal__header" },
                        React.createElement("h5", { className: "text-modal  modal__title" }, this.props.otId ? "Modificar Orden de Trabajo" : "Nueva Orden de Trabajo")),
                    React.createElement(ModalBody, null,
                        React.createElement(Label, { className: 'h4' }, "OT"),
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement(FormGroup, null,
                                    React.createElement(Label, { for: "client" }, "Cliente"),
                                    React.createElement(Input, { type: "select", name: "clientselect", id: "clientselectcomponent", value: this.state.customerId, onChange: this.handleCustomerChange }, this.state.customers.map(function (customer) {
                                        return (React.createElement("option", { key: customer.id, value: customer.id }, customer.fullName));
                                    }))),
                                React.createElement(FormGroup, null,
                                    React.createElement(Label, { for: "employee" }, "Encargado"),
                                    React.createElement(Input, { type: "select", name: "employeeselect", id: "employeeselectcomponent", value: this.state.employeeId, onChange: this.handleEmployeeChange },
                                        React.createElement("option", { key: 0, value: 0 }, "N/A"),
                                        this.state.employees.map(function (employee) {
                                            return (React.createElement("option", { key: employee.id, value: employee.id }, employee.fullName));
                                        })))),
                            React.createElement(Col, null,
                                React.createElement(FormGroup, null,
                                    React.createElement(Label, { for: "name" }, "Estado"),
                                    React.createElement(Input, { type: "select", name: "state", id: "stateselect", value: this.state.otStateId, onChange: this.handleStateChange }, this.state.otStates.map(function (otState) {
                                        return (React.createElement("option", { key: otState.id, value: otState.id }, otState.state));
                                    }))),
                                React.createElement(FormGroup, null,
                                    React.createElement(Label, { for: "name" }, "Presupuesto"),
                                    React.createElement(Input, { type: "number", min: "0", step: "0.1", required: true, value: this.state.budget, onChange: this.handleBudgetChange })))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "name" }, "Direcci\u00F3n"),
                            React.createElement(Input, { type: "text", value: this.state.address, required: false, maxLength: 256, onChange: this.handleAddressChange })),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "name" }, "Tipo de Cliente"),
                            React.createElement(Input, { type: "select", value: this.state.customerTypeId, required: false, maxLength: 256, onChange: this.handleCustomerTypeChange }, this.state.customerTypes.map(function (type) {
                                return (React.createElement("option", { key: type.id, value: type.id }, type.name));
                            }))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "name" }, "Descripci\u00F3n de falla"),
                            React.createElement(Input, { type: "text", value: this.state.errorDescription, required: true, maxLength: 256, onChange: this.handleErrorDescriptionChange })),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "receivedparts" }, "Accesorios recibidos"),
                            React.createElement(Input, { type: "text", value: this.state.recievedParts, required: false, maxLength: 256, onChange: this.handleRecievedPartsChange })),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "motive" }, "Motivo"),
                            React.createElement(Input, { type: "select", value: this.state.motiveId, required: false, maxLength: 256, onChange: this.handleMotiveChange }, this.state.motives.map(function (type) {
                                return (React.createElement("option", { key: type.id, value: type.id }, type.name));
                            }))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "\u00BFAgregar c\u00F3digo de autorizacion?"),
                            React.createElement(Checkbox, { color: "primary", onChange: this.handleCheckAuthorizationChange, checked: this.state.hasAuthorizationCode, inputProps: { 'aria-label': 'primary checkbox' } }),
                            this.state.hasAuthorizationCode ?
                                React.createElement(Input, { type: "text", value: this.state.authorizationCode, required: false, maxLength: 256, onChange: this.handleAuthorizationCodeChange }) : null),
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement(FormGroup, null,
                                    React.createElement(Label, { for: "date" }, "Fecha de ingreso"),
                                    React.createElement(Input, { type: "date", name: "beginDate", id: "stampBeginDateId", value: (_a = this.state.beginDate) === null || _a === void 0 ? void 0 : _a.substr(0, 10), onChange: this.handleBeginDateChange, invalid: this.state.isBeginDateValid }))),
                            React.createElement(Col, { style: { visibility: this.props.otId ? "visible" : "hidden" } },
                                React.createElement(FormGroup, null,
                                    React.createElement(Label, { for: "date" }, "Fecha de salida"),
                                    React.createElement(Input, { type: "date", name: "endDate", id: "stampEndDateId", value: (_b = this.state.endDate) === null || _b === void 0 ? void 0 : _b.substr(0, 10), onChange: this.handleEndDateChange, invalid: this.state.isEndDateValid })))),
                        React.createElement(Label, { className: 'h4', for: "name" }, "Producto"),
                        React.createElement(FormGroup, null,
                            React.createElement(Row, null,
                                React.createElement(Col, null,
                                    React.createElement(FormGroup, null,
                                        React.createElement(Label, { for: "numberserial" }, "N\u00FAmero de serie"),
                                        React.createElement(Input, { type: "text", value: (_c = this.state.product) === null || _c === void 0 ? void 0 : _c.serialNumber, name: "numerialserial", id: "numerialSerialInput", onChange: this.handleSerialNumberChange, maxLength: 50, required: true }))),
                                React.createElement(Col, null,
                                    React.createElement(FormGroup, null,
                                        React.createElement(Label, { for: "date" }, "Fecha de compra"),
                                        React.createElement(Input, { type: "date", name: "purchaseDate", id: "stampDateId", value: (_e = (_d = this.state.product) === null || _d === void 0 ? void 0 : _d.purchaseDate) === null || _e === void 0 ? void 0 : _e.substr(0, 10), onChange: this.handlePurchaseDateChange, invalid: this.state.isPurchaseDateValid })))),
                            React.createElement(FormGroup, null,
                                React.createElement(Label, { for: "type" }, "Tipo de producto"),
                                React.createElement(Input, { type: "select", name: "typeProduct", id: "typeProductSelect", onChange: this.handleProductTypeChange, value: (_f = this.state.product) === null || _f === void 0 ? void 0 : _f.typeId, required: true }, this.state.productTypes.map(function (productType) {
                                    return (React.createElement("option", { key: productType.id, value: productType.id }, productType.name));
                                }))),
                            React.createElement(Row, null,
                                React.createElement(Col, null,
                                    React.createElement(FormGroup, null,
                                        React.createElement(Label, { for: "brand" }, "Marca"),
                                        React.createElement(Creatable, { onChange: this.handleBrandChange, options: this.state.brands.map(function (brand) {
                                                var _a, _b;
                                                return {
                                                    value: (_a = brand.id) === null || _a === void 0 ? void 0 : _a.toString(),
                                                    label: (_b = brand.name) === null || _b === void 0 ? void 0 : _b.toString()
                                                };
                                            }), isClearable: true, onCreateOption: function (event) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.handleCreateBrand(event)];
                                                    case 1: return [2 /*return*/, _a.sent()];
                                                }
                                            }); }); }, value: this.state.brandId ? { value: "" + this.state.brandId, label: "" + this.state.brandLabel } : null, placeholder: "Seleccionar...", formatCreateLabel: function (label) { return "Crear: " + label; }, noOptionsMessage: function () { return "No hay marcas"; } }))),
                                React.createElement(Col, null,
                                    React.createElement(FormGroup, null,
                                        React.createElement(Label, { for: "model" }, "Modelo"),
                                        React.createElement(Creatable, { onChange: this.handleProductModelChange, options: this.state.models.map(function (model) {
                                                var _a, _b;
                                                return {
                                                    value: (_a = model.id) === null || _a === void 0 ? void 0 : _a.toString(),
                                                    label: (_b = model.name) === null || _b === void 0 ? void 0 : _b.toString()
                                                };
                                            }), isClearable: true, onCreateOption: this.handleCreateModel, value: ((_g = this.state.product) === null || _g === void 0 ? void 0 : _g.model) ? { value: "" + this.state.product.model, label: "" + this.state.product.modelLabel } : null, placeholder: "Seleccionar...", isDisabled: !this.state.brandId, formatCreateLabel: function (label) { return "Crear: " + label; }, noOptionsMessage: function () { return "No hay modelos"; } })))),
                            React.createElement(FormGroup, null,
                                React.createElement(Label, { for: "state" }, "Estado del producto"),
                                React.createElement(Input, { type: "text", value: (_h = this.state.product) === null || _h === void 0 ? void 0 : _h.state, onChange: this.handleProductStateChange, maxLength: 160, required: true })),
                            React.createElement(FormGroup, null,
                                React.createElement(Label, { for: "name" }, "Diagn\u00F3stico"),
                                React.createElement(Input, { type: "text", value: (_k = (_j = this.state.product) === null || _j === void 0 ? void 0 : _j.diagnostic) === null || _k === void 0 ? void 0 : _k.description, onChange: this.handleDiagnosticChange, maxLength: 160, required: false })))),
                    React.createElement(ModalFooter, null,
                        React.createElement(Alert, { color: "danger", style: { color: "white", padding: "1%" }, isOpen: !!this.state.errorMessage }, this.state.errorMessage),
                        React.createElement(Button, { color: "primary", type: "submit" }, this.props.otId ? "Modificar" : "Agregar"),
                        ' ',
                        React.createElement(Button, { color: "secondary", onClick: this.props.onClose }, "Cancelar")))));
    };
    return OtForm;
}(React.Component));
export default reduxForm({ form: 'vertical_form_validation' })(OtForm);
