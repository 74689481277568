var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert, Form } from 'reactstrap';
import { FormGroup, Modal, ModalBody, Label, Input, ModalFooter, Button, } from 'reactstrap';
;
var incidentErrorMsg = "Hubo un error en el servidor";
var IncidentForm = /** @class */ (function (_super) {
    __extends(IncidentForm, _super);
    function IncidentForm(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            employees: [],
            employeeId: 0,
            detail: '',
            stampDateTime: (new Date(Date.now())).toISOString(),
            messageError: '',
            isDateValid: false,
        };
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        _this.handleDetailChange = _this.handleDetailChange.bind(_this);
        _this.handleDateChange = _this.handleDateChange.bind(_this);
        _this.handleTimeChange = _this.handleTimeChange.bind(_this);
        _this.handleEmployeeChange = _this.handleEmployeeChange.bind(_this);
        return _this;
    }
    IncidentForm.prototype.componentDidMount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var employees, employee, incidents, incident;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/employee")];
                    case 1:
                        employees = _b.sent();
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/employee/" + localStorage.getItem('id'))];
                    case 2:
                        employee = _b.sent();
                        this.setState({
                            employees: employees.data.map(function (employee) { return { id: employee.id, fullName: employee.fullName }; }),
                            employeeId: employee.data ? localStorage.getItem('id') : ((_a = employees.data[0]) === null || _a === void 0 ? void 0 : _a.id) || 0,
                        });
                        if (!this.props.incidentId) return [3 /*break*/, 4];
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/ot/incident/" + this.props.otId)];
                    case 3:
                        incidents = _b.sent();
                        incident = incidents.data.filter(function (incident) {
                            return incident.id == _this.props.incidentId;
                        });
                        this.setState({
                            employeeId: incident[0].employee.id,
                            detail: incident[0].detail,
                            stampDateTime: (new Date(incident[0].stamp)).toISOString(),
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        this.setState({
                            detail: '',
                            stampDateTime: (new Date(Date.now())).toISOString(),
                            messageError: '',
                        });
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    IncidentForm.prototype.isFormValid = function () {
        var isDateValid = this.state.isDateValid;
        if (isDateValid) {
            this.setState({
                messageError: 'Verifique el formulario, hay campos erroneos'
            });
        }
        ;
        return !isDateValid;
    };
    IncidentForm.prototype.handleSubmit = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var incident, err_1, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        if (!this.isFormValid()) return [3 /*break*/, 8];
                        if (!this.props.incidentId) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.put(process.env.REACT_APP_SERVER_URL + "/ot/incident/" + this.props.incidentId, {
                                employeeId: this.state.employeeId,
                                detail: this.state.detail,
                                stamp: this.state.stampDateTime,
                            })];
                    case 2:
                        incident = _a.sent();
                        toast.success("Suceso modificado exitosamente");
                        this.props.onClose();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.log(err_1);
                        toast.error(incidentErrorMsg);
                        this.setState({ messageError: err_1.message });
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 8];
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/ot/incident", {
                                otId: this.props.otId,
                                employeeId: this.state.employeeId,
                                detail: this.state.detail,
                                stamp: this.state.stampDateTime,
                            })];
                    case 6:
                        _a.sent();
                        toast.success("Suceso creado exitosamente");
                        this.props.onClose();
                        return [3 /*break*/, 8];
                    case 7:
                        err_2 = _a.sent();
                        console.log(err_2);
                        toast.error(incidentErrorMsg);
                        this.setState({ messageError: err_2.message });
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    IncidentForm.prototype.handleDetailChange = function (event) {
        var target = event.target;
        this.setState({
            detail: target.value,
        });
    };
    IncidentForm.prototype.handleDateChange = function (event) {
        var target = event.target;
        var value = (new Date(target.value)).toISOString();
        var onlyDate = value.split('T')[0];
        var onlyTimeState = this.state.stampDateTime.split('T')[1];
        var dateFormmat = onlyDate + "T" + onlyTimeState;
        var dateNow = new Date();
        var isFuture = dateNow.getTime() > new Date(dateFormmat).getTime();
        this.setState({
            stampDateTime: dateFormmat,
            isDateValid: !isFuture,
        });
    };
    IncidentForm.prototype.handleTimeChange = function (event) {
        var target = event.target;
        var value = target.value.substr(0, 5);
        var onlyDateState = this.state.stampDateTime.split('T')[0];
        var dateFormmat = onlyDateState + "T" + value + ":00.000Z";
        var dateNow = new Date();
        var isFuture = dateNow.getTime() > new Date(dateFormmat).getTime();
        this.setState({
            stampDateTime: dateFormmat,
            isDateValid: !isFuture,
        });
    };
    IncidentForm.prototype.handleEmployeeChange = function (event) {
        var target = event.target;
        this.setState({
            employeeId: parseInt(target.value),
        });
    };
    IncidentForm.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { isOpen: this.props.isModalOpen, className: "modal-dialog--primary modal-dialog--header" },
                React.createElement(Form, { onSubmit: this.handleSubmit },
                    React.createElement("div", { className: "modal__header" },
                        React.createElement("h5", { className: "text-modal  modal__title" }, this.props.incidentId ? 'Modificar suceso' : 'Añadir suceso')),
                    React.createElement(ModalBody, null,
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "employee" }, "Empleados"),
                            React.createElement(Input, { type: "select", name: "employee", id: "employeeSelect", onChange: this.handleEmployeeChange, value: this.state.employeeId }, this.state.employees.map(function (employee) {
                                return (React.createElement("option", { key: employee.id, value: employee.id }, employee.fullName));
                            }))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "detail" }, "Detalle"),
                            React.createElement(Input, { type: "textarea", value: this.state.detail, onChange: this.handleDetailChange, maxLength: 160, required: true })),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "stampDateTime" }, "Fecha"),
                            React.createElement(Input, { type: "date", name: "stampDateTime", id: "stampDateId", value: this.state.stampDateTime.substr(0, 10), onChange: this.handleDateChange, invalid: this.state.isDateValid, required: true })),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "stampDateTime" }, "Hora"),
                            React.createElement(Input, { type: "time", name: "stampDateTime", id: "stampTimeId", value: this.state.stampDateTime.substr(11, 5), onChange: this.handleTimeChange, invalid: this.state.isDateValid, required: true }))),
                    React.createElement(ModalFooter, null,
                        React.createElement(Alert, { color: "danger", style: { color: "white", padding: "1%" }, isOpen: !!this.state.messageError }, this.state.messageError),
                        React.createElement(Button, { color: "primary", type: "submit" }, this.props.incidentId ? 'Modificar' : 'Añadir'),
                        ' ',
                        React.createElement(Button, { color: "secondary", onClick: this.props.onClose }, "Cancelar")))),
            React.createElement(ToastContainer, null)));
    };
    return IncidentForm;
}(React.Component));
export default IncidentForm;
