import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
// import Landing from '../../Landing/index';
import NotFound404 from '../../DefaultPage/404/index';
import LogIn from '../../Account/LogIn/index';
import Register from '../../Account/Register/index';
import WrappedRoutes from './WrappedRoutes';


const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route path="/404" component={NotFound404} />
        <Route path="/log_in" component={LogIn} />
        <Route path="/register" component={Register} />
        {localStorage.getItem('id') ? <Route path="/" component={WrappedRoutes} /> : <Route path="/" component={NotFound404} />}
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
