var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from 'reactstrap';
import { FormGroup, Modal, ModalBody, Label, Input, ModalFooter, Button, } from 'reactstrap';
import { Creatable } from 'react-select';
;
var modelErrorMsg = "Hubo un error en el servidor";
var ModelForm = /** @class */ (function (_super) {
    __extends(ModelForm, _super);
    function ModelForm(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            name: '',
            brands: [],
            brandId: null,
            brandLabel: null
        };
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        _this.handleNameChange = _this.handleNameChange.bind(_this);
        _this.handleBrandChange = _this.handleBrandChange.bind(_this);
        _this.getBrands = _this.getBrands.bind(_this);
        return _this;
    }
    ModelForm.prototype.getBrands = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/brand")
                            .then(function (res) {
                            _this.setState({ brands: res.data });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModelForm.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var model;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrands()];
                    case 1:
                        _a.sent();
                        if (!this.props.modelId) return [3 /*break*/, 3];
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/model/" + this.props.modelId)];
                    case 2:
                        model = _a.sent();
                        this.setState({
                            name: model.data.name,
                            verifyName: false,
                            brandId: model.data.brand.id,
                            brandLabel: model.data.brand.name,
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        this.setState({
                            name: '',
                            verifyName: false,
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ModelForm.prototype.handleSubmit = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var model, err_1, model, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        if (!this.state.brandId) return [3 /*break*/, 9];
                        if (!this.props.modelId) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.put(process.env.REACT_APP_SERVER_URL + "/model/" + this.props.modelId, {
                                name: this.state.name,
                                brandId: this.state.brandId,
                            })];
                    case 2:
                        model = _a.sent();
                        toast.success("Modelo modificado exitosamente");
                        this.props.onClose();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.log(err_1);
                        toast.error(modelErrorMsg);
                        this.setState({ messageError: err_1.message });
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 8];
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/model", {
                                name: this.state.name,
                                brandId: this.state.brandId,
                            })];
                    case 6:
                        model = _a.sent();
                        toast.success("Modelo agregado exitosamente");
                        this.props.onClose();
                        return [3 /*break*/, 8];
                    case 7:
                        err_2 = _a.sent();
                        console.log(err_2);
                        toast.error(modelErrorMsg);
                        this.setState({ messageError: err_2.message });
                        return [3 /*break*/, 8];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        toast.error('No puede crear un modelo sin marca');
                        _a.label = 10;
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ModelForm.prototype.handleNameChange = function (event) {
        var _a;
        var target = event.target;
        this.setState((_a = {}, _a[target.name] = target.value, _a));
    };
    ModelForm.prototype.handleBrandChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var brandId;
            return __generator(this, function (_a) {
                if (event) {
                    brandId = parseInt(event.value);
                    this.setState({
                        brandId: brandId,
                        brandLabel: event.label,
                    });
                }
                else {
                    this.setState({
                        brandId: null,
                        brandLabel: null,
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    ModelForm.prototype.handleCreateBrand = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/brand", { name: event })
                            .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                            var brandId;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.getBrands()];
                                    case 1:
                                        _a.sent();
                                        brandId = this.state.brands.find(function (brand) {
                                            return brand.name == event;
                                        });
                                        this.setState({
                                            brandId: brandId === null || brandId === void 0 ? void 0 : brandId.id,
                                            brandLabel: event
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); }).catch(function (err) {
                            console.log(err);
                            toast.error(modelErrorMsg);
                            _this.setState({
                                brandId: 0,
                                brandLabel: 'N/A'
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModelForm.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { isOpen: this.props.isModalOpen, className: "modal-dialog--primary modal-dialog--header" },
                React.createElement(Form, { onSubmit: this.handleSubmit },
                    React.createElement("div", { className: "modal__header" },
                        React.createElement("h5", { className: "text-modal  modal__title" }, this.props.modelId ? 'Modificar Modelo' : 'Añadir Modelo')),
                    React.createElement(ModalBody, null,
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "detail" }, "Nombre"),
                            React.createElement(Input, { type: "text", name: "name", value: this.state.name, onChange: this.handleNameChange, maxLength: 120, required: true }))),
                    React.createElement(ModalBody, null,
                        React.createElement(FormGroup, null,
                            React.createElement(Label, { for: "brand" }, "Marca"),
                            React.createElement(Creatable, { onChange: this.handleBrandChange, options: this.state.brands.map(function (brand) {
                                    var _a, _b;
                                    return {
                                        value: (_a = brand.id) === null || _a === void 0 ? void 0 : _a.toString(),
                                        label: (_b = brand.name) === null || _b === void 0 ? void 0 : _b.toString()
                                    };
                                }), isClearable: true, onCreateOption: function (event) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.handleCreateBrand(event)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); }, value: this.state.brandId ? { value: "" + this.state.brandId, label: "" + this.state.brandLabel } : null, placeholder: "Seleccionar...", formatCreateLabel: function (label) { return "Crear: " + label; }, noOptionsMessage: function () { return "No hay marcas"; } }))),
                    React.createElement(ModalFooter, null,
                        React.createElement(Button, { color: "primary", type: "submit" }, this.props.modelId ? 'Modificar' : 'Agregar'),
                        ' ',
                        React.createElement(Button, { color: "secondary", onClick: this.props.onClose }, "Cancelar")))),
            React.createElement(ToastContainer, null)));
    };
    return ModelForm;
}(React.Component));
export default ModelForm;
