var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React from 'react';
import MaterialTable from "material-table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tableIcons } from "../../shared/TableIcons";
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import CustomerForm from './customer-form';
import { TablePagination } from '@material-ui/core';
function NotifyError() {
    toast.error("Hubo un error en el servidor");
}
var fields = [
    'id',
    'fullName',
    'dni',
    'phones[0].number',
    'fiscalCondition.name',
];
var CustomerListView = /** @class */ (function (_super) {
    __extends(CustomerListView, _super);
    function CustomerListView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            customers: [],
            isLoading: true,
            customerId: 0,
            fiscalConditionId: 0,
            isModalOpen: false,
            count: 0,
            firstLoad: true,
            page: 1,
            pageSize: 15,
            search: '',
            sortField: '',
            sortType: '',
            delayTimer: 0,
        };
        _this.openModal = _this.openModal.bind(_this);
        _this.closeModal = _this.closeModal.bind(_this);
        _this.updateTable = _this.updateTable.bind(_this);
        _this.handleSearchChange = _this.handleSearchChange.bind(_this);
        return _this;
    }
    CustomerListView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateTable()];
                    case 1:
                        _a.sent();
                        this.setState({
                            firstLoad: false,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerListView.prototype.updateTable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var customersEndpoint, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        customersEndpoint = (process.env.REACT_APP_SERVER_URL + "/customer")
                            .concat("?take=" + this.state.pageSize, "&skip=" + this.state.pageSize * (this.state.page - 1), "&sortField=" + this.state.sortField, "&sortType=" + this.state.sortType, "&search=" + this.state.search);
                        return [4 /*yield*/, axios.get(customersEndpoint)
                                .then(function (res) {
                                _this.setState({ customers: res.data.customers, count: res.data.count });
                            })];
                    case 1:
                        _a.sent();
                        this.setState({ isLoading: false });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        NotifyError();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CustomerListView.prototype.openModal = function () {
        this.setState({
            isModalOpen: true
        });
    };
    ;
    CustomerListView.prototype.closeModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            isModalOpen: false,
                            customerId: 0,
                            fiscalConditionId: 0,
                        });
                        return [4 /*yield*/, this.updateTable()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerListView.prototype.handleChangePage = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({
                    page: page,
                    isLoading: true,
                });
                return [2 /*return*/];
            });
        });
    };
    CustomerListView.prototype.handleChangeRowPerPage = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({
                    pageSize: parseInt(value),
                    page: 1,
                    isLoading: true,
                });
                return [2 /*return*/];
            });
        });
    };
    CustomerListView.prototype.handleOrderChange = function (column) {
        return __awaiter(this, void 0, void 0, function () {
            var type;
            return __generator(this, function (_a) {
                type = 'ASC';
                if (this.state.sortField == fields[column]) {
                    type = this.state.sortType == 'ASC' ? 'DESC' : 'ASC';
                }
                this.setState({
                    sortField: fields[column],
                    sortType: type,
                    isLoading: true,
                });
                return [2 /*return*/];
            });
        });
    };
    CustomerListView.prototype.handleSearchChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                window.clearTimeout(this.state.delayTimer);
                this.setState({
                    delayTimer: window.setTimeout(function () {
                        _this.setState({
                            search: event,
                            page: 1,
                            isLoading: true,
                        });
                        _this.updateTable();
                    }, 300)
                });
                return [2 /*return*/];
            });
        });
    };
    CustomerListView.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { style: { padding: "0px 20px 20px 20px" } },
            React.createElement(MaterialTable, { isLoading: this.state.isLoading, data: this.state.customers, icons: tableIcons, title: "Clientes", options: { pageSize: this.state.pageSize, actionsColumnIndex: -1, sorting: true, pageSizeOptions: [15, 30, 50] }, columns: [
                    { title: '#', field: fields[0], cellStyle: { width: 20, maxWidth: 20 }, headerStyle: { width: 25, maxWidth: 25 }, sorting: true, editable: "never" },
                    { title: 'Nombre completo', field: fields[1] },
                    { title: 'Dni', field: fields[2], cellStyle: { width: 50, maxWidth: 50 }, headerStyle: { width: 50, maxWidth: 50 } },
                    { title: 'Teléfono', field: fields[3], cellStyle: { width: 50, maxWidth: 50 }, headerStyle: { width: 50, maxWidth: 50 } },
                    { title: 'Condición Fiscal', field: fields[4], render: function (rowData) { var _a; return ((_a = rowData === null || rowData === void 0 ? void 0 : rowData.fiscalCondition) === null || _a === void 0 ? void 0 : _a.name) ? rowData.fiscalCondition.name : 'N/A'; }, },
                ], detailPanel: function (rowData) {
                    return (React.createElement("div", { style: { padding: "20px 0px 20px 20px" } },
                        React.createElement("h5", { style: { marginBottom: "20px" } },
                            "Correo: ",
                            rowData.email && rowData.email != "" ? rowData.email : "N/A"),
                        React.createElement("h5", { style: { marginBottom: "20px" } },
                            "Direcci\u00F3n: ",
                            rowData.addresses !== undefined && rowData.addresses[0] !== undefined && rowData.addresses[0].address != "" ? rowData.addresses[0].address : "N/A"),
                        React.createElement("h5", { style: { marginBottom: "20px" } },
                            "Barrio: ",
                            rowData.neighbourhood !== undefined && rowData.neighbourhood != null && rowData.neighbourhood.trim() != "" ? rowData.neighbourhood : "N/A"),
                        React.createElement("h5", null, "Phones:"),
                        rowData.phones.map(function (phone) {
                            return (React.createElement("p", { style: { marginLeft: "2%" } },
                                "Numero de ",
                                phone.isMobile ? 'Celular' : 'Telefono',
                                ":  ",
                                phone.number));
                        })));
                }, actions: [
                    {
                        icon: AddBox,
                        onClick: function (event, rowData) {
                            _this.setState({
                                customerId: 0,
                            });
                            _this.openModal();
                        },
                        isFreeAction: true,
                        tooltip: 'Nuevo Cliente',
                    },
                    {
                        icon: Edit,
                        onClick: function (event, rowData) {
                            var _a;
                            _this.setState({
                                customerId: rowData.id,
                                fiscalConditionId: (_a = rowData.fiscalCondition) === null || _a === void 0 ? void 0 : _a.id,
                            });
                            _this.openModal();
                        },
                        tooltip: 'Editar',
                    }
                ], onOrderChange: function (column) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.handleOrderChange(column)];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, this.updateTable()];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, onSearchChange: function (event) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.handleSearchChange(event)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, localization: {
                    body: { deleteTooltip: 'Borrar', emptyDataSourceMessage: "No existen registros para esta tabla", editRow: { deleteText: '¿Está seguro de eliminar este elemento?' } },
                    header: { actions: "Acciones" },
                    toolbar: { searchPlaceholder: 'Buscar', searchTooltip: '' }
                }, editable: {
                    onRowDelete: function (customerToDelete) {
                        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                            var err_2;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        this.setState({
                                            page: 1,
                                        });
                                        return [4 /*yield*/, axios.delete(process.env.REACT_APP_SERVER_URL + "/customer/" + customerToDelete.id).then(function () { return _this.updateTable(); })];
                                    case 1:
                                        _a.sent();
                                        resolve();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        err_2 = _a.sent();
                                        NotifyError();
                                        console.log(err_2);
                                        reject();
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                    },
                }, components: {
                    Pagination: function (props) { return (React.createElement(TablePagination, __assign({}, props, { rowsPerPage: _this.state.pageSize, count: _this.state.count, page: _this.state.firstLoad
                            ? _this.state.page
                            : _this.state.page - 1, onChangePage: function (event, page) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.handleChangePage(page + 1)];
                                    case 1:
                                        _a.sent();
                                        this.updateTable();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, onChangeRowsPerPage: function (event) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        props.onChangeRowsPerPage(event);
                                        return [4 /*yield*/, this.handleChangeRowPerPage(event.target.value)];
                                    case 1:
                                        _a.sent();
                                        return [4 /*yield*/, this.updateTable()];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } }))); },
                } }),
            React.createElement(ToastContainer, null),
            this.state.isModalOpen ? React.createElement(CustomerForm, { isModalOpen: this.state.isModalOpen, onClose: this.closeModal, fiscalConditionId: this.state.fiscalConditionId, customerId: this.state.customerId }) : null));
    };
    ;
    return CustomerListView;
}(React.Component));
export default CustomerListView;
