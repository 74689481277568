var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Input, Form } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Checkbox } from '@material-ui/core';
var employeeErrorMsg = "Hubo un error en el servidor";
var EmployeeForm = /** @class */ (function (_super) {
    __extends(EmployeeForm, _super);
    function EmployeeForm(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChangeOurscourced = function (event) {
            _this.setState({ isOutsourced: event.target.checked });
        };
        _this.state = {
            fullName: '',
            dni: '',
            email: '',
            phone: '',
            isOutsourced: false,
            isLoading: true,
        };
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        _this.handleChangeFullName = _this.handleChangeFullName.bind(_this);
        _this.handleChangeDni = _this.handleChangeDni.bind(_this);
        _this.handleChangePhone = _this.handleChangePhone.bind(_this);
        _this.handleChangeEmail = _this.handleChangeEmail.bind(_this);
        _this.handleChangeOurscourced = _this.handleChangeOurscourced.bind(_this);
        return _this;
    }
    EmployeeForm.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.employeeId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getEmployee()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeForm.prototype.getEmployee = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/employee/" + this.props.employeeId)
                                .then(function (res) {
                                var employee = res.data;
                                _this.setState({
                                    fullName: employee.fullName,
                                    dni: employee.dni,
                                    email: employee.email,
                                    phone: employee.phone,
                                    isOutsourced: employee.outsourced,
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        toast.error(employeeErrorMsg);
                        throw new Error(err_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeForm.prototype.handleChangeFullName = function (event) {
        this.setState({ fullName: event.target.value });
    };
    EmployeeForm.prototype.handleChangeDni = function (event) {
        this.setState({ dni: event.target.value });
    };
    EmployeeForm.prototype.handleChangePhone = function (event) {
        this.setState({ phone: event.target.value });
    };
    EmployeeForm.prototype.handleChangeEmail = function (event) {
        this.setState({ email: event.target.value });
    };
    EmployeeForm.prototype.handleSubmit = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        if (!this.props.employeeId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateEmployee()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.addEmployee()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.setState({
                            fullName: '',
                            dni: '',
                            email: '',
                            phone: '',
                            isOutsourced: false,
                            isLoading: true,
                        });
                        return [4 /*yield*/, this.props.onClose()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeForm.prototype.addEmployee = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/employee/", {
                                fullName: this.state.fullName,
                                dni: this.state.dni,
                                email: this.state.email,
                                phone: this.state.phone,
                                outsourced: this.state.isOutsourced
                            })
                                .then(function (response) {
                                console.log(response);
                                toast.success("Empleado creado exitosamente");
                            })
                                .catch(function (error) {
                                console.log(error);
                                toast.error(error);
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        toast.error(employeeErrorMsg);
                        console.error(err_2);
                        throw new Error(err_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeForm.prototype.updateEmployee = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.put(process.env.REACT_APP_SERVER_URL + "/employee/" + this.props.employeeId, {
                                fullName: this.state.fullName,
                                dni: this.state.dni,
                                email: this.state.email,
                                phone: this.state.phone,
                                outsourced: this.state.isOutsourced
                            })
                                .then(function (response) {
                                console.log(response);
                            })
                                .catch(function (error) {
                                console.log(error);
                            })];
                    case 1:
                        _a.sent();
                        toast.success("Empleado modificado exitosamente");
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        console.error(err_3);
                        toast.error(employeeErrorMsg);
                        throw new Error(err_3);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeForm.prototype.render = function () {
        return (React.createElement(Modal, { isOpen: this.props.isModalOpen, toggle: this.props.onClose, className: "modal-dialog--primary modal-dialog--header" },
            React.createElement(Form, { onSubmit: this.handleSubmit },
                React.createElement("div", { className: "modal__header" },
                    React.createElement("h5", { className: "text-modal  modal__title" }, this.props.employeeId ? 'Modificar Empleado' : 'Añadir Empleado')),
                React.createElement(ModalBody, null,
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "name" }, "Nombre completo"),
                        React.createElement(Input, { type: "text", value: this.state.fullName, onChange: this.handleChangeFullName, maxLength: 60, required: true })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "name" }, "Dni"),
                        React.createElement(Input, { type: "number", value: this.state.dni, onChange: this.handleChangeDni, max: Math.pow(10, 11), required: true })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "name" }, "Correo"),
                        React.createElement(Input, { type: "email", value: this.state.email, onChange: this.handleChangeEmail, required: true })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "name" }, "Tel/Cel"),
                        React.createElement(Input, { type: "number", value: this.state.phone, onChange: this.handleChangePhone, max: Math.pow(10, 16), required: true })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "name" }, "\u00BFEs Externo?"),
                        React.createElement(Checkbox, { color: "primary", checked: this.state.isOutsourced, onChange: this.handleChangeOurscourced, inputProps: { 'aria-label': 'primary checkbox' } }))),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { color: "primary", type: "submit" }, this.props.employeeId ? 'Modificar' : 'Agregar'),
                    React.createElement(Button, { color: "secondary", onClick: this.props.onClose }, "Cancelar")))));
    };
    return EmployeeForm;
}(React.Component));
export default reduxForm({ form: 'vertical_form_validation' })(EmployeeForm);
