var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Label, Input, FormGroup, Button, ModalBody, ModalFooter, Form, Row, Col } from 'reactstrap';
var customerErrorMsg = "Hubo un error en el servidor";
var CustomerForm = /** @class */ (function (_super) {
    __extends(CustomerForm, _super);
    function CustomerForm(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            fullName: '',
            dni: '',
            email: '',
            fiscalCondition: [],
            fiscalConditionId: 0,
            address: '',
            addressId: 0,
            neighbourhood: '',
            messageError: '',
            neighborhoodId: 0,
            phones: [],
        };
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        _this.handleTextChange = _this.handleTextChange.bind(_this);
        _this.handleNumberChange = _this.handleNumberChange.bind(_this);
        _this.addPhone = _this.addPhone.bind(_this);
        _this.handlePhoneChange = _this.handlePhoneChange.bind(_this);
        return _this;
    }
    CustomerForm.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var phoneDummy, phones;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadDropdownOptions()];
                    case 1:
                        _a.sent();
                        if (!this.props.customerId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getCustomer()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        phoneDummy = {
                            id: 0,
                            number: '',
                            isMobile: false
                        };
                        phones = [phoneDummy];
                        this.setState({
                            phones: phones,
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerForm.prototype.loadDropdownOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/customer/fiscalcondition").then(function (res) {
                                var fiscalConditions = res.data;
                                _this.setState({ fiscalCondition: fiscalConditions });
                            })];
                    case 1:
                        _a.sent();
                        this.setState({
                            fiscalConditionId: this.state.fiscalCondition.length > 0 ? this.state.fiscalCondition[0].id : 0,
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        toast.error("Hubo un error en el servidor");
                        console.error(err_1);
                        throw new Error(err_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CustomerForm.prototype.getCustomer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/customer/" + this.props.customerId)
                                .then(function (res) {
                                var _a, _b;
                                var customer = res.data;
                                _this.setState({
                                    fullName: customer.fullName,
                                    dni: customer.dni,
                                    email: customer.email,
                                    fiscalConditionId: ((_a = customer === null || customer === void 0 ? void 0 : customer.fiscalCondition) === null || _a === void 0 ? void 0 : _a.id) ? (_b = customer === null || customer === void 0 ? void 0 : customer.fiscalCondition) === null || _b === void 0 ? void 0 : _b.id : _this.state.fiscalCondition.length > 0 ? _this.state.fiscalCondition[0].id : 0,
                                    phones: customer.phones,
                                    address: customer.addresses[0].address,
                                    addressId: customer.addresses[0].id,
                                    neighbourhood: customer.neighbourhood,
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        console.error(err_2);
                        toast.error(customerErrorMsg);
                        throw new Error(err_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CustomerForm.prototype.addCustomer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post(process.env.REACT_APP_SERVER_URL + "/customer/", {
                                fullName: this.state.fullName,
                                dni: this.state.dni,
                                email: this.state.email,
                                contacts: [],
                                phones: this.state.phones,
                                addressesInput: [
                                    {
                                        address: this.state.address,
                                    }
                                ],
                                fiscalConditionId: this.state.fiscalConditionId,
                                neighbourhood: this.state.neighbourhood,
                            })
                                .then(function (response) {
                                console.log(response);
                            })
                                .catch(function (error) {
                                console.log(error);
                            })];
                    case 1:
                        _a.sent();
                        toast.success("Cliente creado exitosamente");
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        toast.error(customerErrorMsg);
                        console.error(err_3);
                        throw new Error(err_3);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CustomerForm.prototype.updateCustomer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.put(process.env.REACT_APP_SERVER_URL + "/customer/" + this.props.customerId, {
                                fullName: this.state.fullName,
                                dni: this.state.dni,
                                email: this.state.email,
                                phones: this.state.phones,
                                addressesInput: [
                                    {
                                        address: this.state.address,
                                        id: this.state.addressId,
                                    }
                                ],
                                fiscalConditionId: this.state.fiscalConditionId,
                                neighbourhood: this.state.neighbourhood,
                            })
                                .then(function (response) {
                                console.log(response);
                            })
                                .catch(function (error) {
                                console.log(error);
                            })];
                    case 1:
                        _a.sent();
                        toast.success("Cliente modificado exitosamente");
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        console.error(err_4);
                        toast.error(customerErrorMsg);
                        throw new Error(err_4);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CustomerForm.prototype.handleSubmit = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        if (!this.props.customerId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateCustomer()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.addCustomer()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.props.onClose();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerForm.prototype.handleTextChange = function (event) {
        var _a;
        this.setState((_a = {}, _a[event.target.name] = event.target.value, _a));
    };
    CustomerForm.prototype.handleNumberChange = function (event) {
        var _a;
        if (parseInt(event.target.value)) {
            this.setState((_a = {}, _a[event.target.name] = event.target.value, _a));
        }
    };
    CustomerForm.prototype.handlePhoneChange = function (event) {
        var index = parseInt(event.target.name.split('-')[1]);
        var phones = this.state.phones.slice();
        if (event.target.name.startsWith('isMobile')) {
            phones[index].isMobile = !phones[index].isMobile;
        }
        else {
            phones[index].number = event.target.value;
        }
        this.setState({
            phones: phones,
        });
    };
    CustomerForm.prototype.addPhone = function () {
        var phone = {
            id: 0,
            number: '',
            isMobile: false
        };
        var phones = this.state.phones.slice();
        phones.push(phone);
        this.setState({
            phones: phones,
        });
    };
    CustomerForm.prototype.removePhone = function (index) {
        var phonesInit = this.state.phones.slice(0, index);
        var phonesEnd = this.state.phones.slice(index + 1, this.state.phones.length);
        this.setState({
            phones: phonesInit.concat(phonesEnd),
        });
    };
    CustomerForm.prototype.render = function () {
        var _this = this;
        return (React.createElement(Modal, { isOpen: this.props.isModalOpen, toggle: this.props.onClose, className: "modal-dialog--primary modal-dialog--header" },
            React.createElement(Form, { onSubmit: this.handleSubmit },
                React.createElement("div", { className: "modal__header" },
                    React.createElement("h5", { className: "text-modal  modal__title" }, this.props.customerId ? "Modificar Cliente" : "Nuevo Cliente")),
                React.createElement(ModalBody, null,
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "name" }, "Nombre completo"),
                        React.createElement(Input, { type: "text", name: "fullName", value: this.state.fullName, onChange: this.handleTextChange, required: true })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "address" }, "Direcci\u00F3n"),
                        React.createElement(Input, { type: "text", name: "address", value: this.state.address, onChange: this.handleTextChange, required: true })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "neighbourhood" }, "Barrio"),
                        React.createElement(Input, { type: "text", name: "neighbourhood", value: this.state.neighbourhood, onChange: this.handleTextChange, required: true })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "dni" }, "DNI/CUIT"),
                        React.createElement(Input, { type: "number", name: "dni", id: "customerDni", value: this.state.dni, onChange: this.handleTextChange, required: false })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "numberPhone" }, "Tel\u00E9fono/Celular"),
                        React.createElement("br", null),
                        this.state.phones.map(function (phone, index) {
                            return (React.createElement(FormGroup, null,
                                React.createElement(Row, null,
                                    React.createElement(Col, { md: 6 },
                                        React.createElement(Input, { type: "number", onChange: _this.handlePhoneChange, value: phone.number, bsSize: "sm", name: "numberPhone-" + index, id: "numberPhone-" + index, required: true })),
                                    React.createElement(Col, { md: 3 },
                                        React.createElement(FormGroup, { check: true },
                                            React.createElement(Input, { type: "checkbox", onClick: _this.handlePhoneChange, defaultChecked: phone.isMobile, name: "isMobile-" + index, id: "isMobile-" + index }),
                                            React.createElement(Label, { for: "isMobile-" + index, check: true }, "\u00BFEs Celular?"))),
                                    React.createElement(Col, null,
                                        React.createElement(Button, { type: "button", color: "primary", onClick: _this.addPhone }, "+"),
                                        " ",
                                        '\t',
                                        React.createElement(Button, { type: "button", color: "danger", onClick: function () { return _this.removePhone(index); } }, "-")))));
                        }),
                        this.state.phones.length <= 0 ? React.createElement(Button, { type: "button", color: "primary", onClick: this.addPhone }, "+") : ''),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "email" }, "Correo electr\u00F3nico"),
                        React.createElement(Input, { type: "email", name: "email", id: "customerEmail", value: this.state.email, onChange: this.handleTextChange, required: false })),
                    React.createElement(FormGroup, null,
                        React.createElement(Label, { for: "fiscalcondition" }, "Condici\u00F3n Fiscal"),
                        React.createElement(Input, { type: "select", name: "fiscalConditionId", id: "fiscalconditioncomponent", onChange: this.handleTextChange, value: this.state.fiscalConditionId }, this.state.fiscalCondition.map(function (fiscalCondition) {
                            return (React.createElement("option", { key: fiscalCondition.id, value: fiscalCondition.id }, fiscalCondition.name));
                        })))),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { type: "submit", color: "primary" }, this.props.customerId ? "Modificar" : "Agregar"),
                    ' ',
                    React.createElement(Button, { color: "secondary", onClick: this.props.onClose }, "Cancelar")))));
    };
    return CustomerForm;
}(React.Component));
export default CustomerForm;
