import React, { PureComponent } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import axios from 'axios';

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    errorMsg: PropTypes.string,
    fieldUser: PropTypes.string,
    typeFieldUser: PropTypes.string,
    form: PropTypes.string.isRequired,
  };

  static defaultProps = {
    errorMessage: '',
    errorMsg: '',
    fieldUser: 'Nombre de usuario',
    typeFieldUser: 'text',
  }

  constructor() {
    super();
    this.state = {
      showPassword: false,
      username: '',
      password: '',
      errorMessage: '',
    };

    this.showPassword = this.showPassword.bind(this);
    this.usernameHandler = this.usernameHandler.bind(this);
    this.passwordHandler = this.passwordHandler.bind(this);
    this.loginHandler = this.loginHandler.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

    localStorage.removeItem('username');
    localStorage.removeItem('id');
  }

  onKeyPress(event) {
    if (event.keyCode === 27 || event.key === "Enter") {
      this.loginHandler();
    }
  }

  componentDidMount() {
    document.addEventListener("keypress", this.onKeyPress, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keypress", this.onKeyPress, false);
  }

  usernameHandler(e) {
    this.setState({
      username: e.target.value
    });
  }

  passwordHandler(e) {
    this.setState({
      password: e.target.value
    });
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  async loginHandler(e) {
    if (e) e.preventDefault();
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/login/signin`, {
      data: {
        username: this.state.username,
        password: this.state.password
      }
    })
      .then((data) => {
        localStorage.setItem('username', data.data.username);
        localStorage.setItem('id', data.data.id);
        window.location = '/ots';
      })
      .catch((err) => {
        this.setState({
          errorMessage: "Nombre de usuario o contraseña incorrectos"
        })
      })
  }

  render() {
    const { fieldUser, typeFieldUser, form } = this.props;
    const { showPassword } = this.state;
    return (
      <Form className="form login-form" onSubmit={this.loginHandler}>
        <Alert
          color="danger"
          isOpen={!!this.state.errorMessage || !!this.state.errorMsg}
        >
          {this.state.errorMessage}
        </Alert>
        <div className="form__form-group">
          <span className="form__form-group-label">{fieldUser}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              component="input"
              type={typeFieldUser}
              placeholder={fieldUser}
              onChange={this.usernameHandler}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Contraseña</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Contraseña"
              onChange={this.passwordHandler}
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div style={{ display: "grid", width: '100%' }}>
          {
            form === 'modal_login'
              ? <Button className="account__btn" submit="true" onClick={this.loginHandler} color="primary">Iniciar Sesión</Button>
              : (
                <Link type="submit" className="account__btn btn btn-primary" submit="true" onClick={this.loginHandler} style={{ display: "grid", alignSelf: "center" }} to="/">Iniciar Sesión</Link>
              )
          }
        </div>
      </Form>
    );
  }
}

export default connect(state => ({
  errorMsg: state.user.error,
}))(reduxForm()(LogInForm));
