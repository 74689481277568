import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">

          <SidebarCategory title="Órdenes de Trabajo" icon="pencil">
            <SidebarLink title="Lista de OT" route="/ots" onClick={this.hideSidebar} />
            <SidebarLink title="Tipos de Cliente" route="/clientes/tipos" onClick={this.hideSidebar} />
            <SidebarLink title="Motivos" route="/ot/motivos" onClick={this.hideSidebar} />
            <SidebarLink title="Estados" route="/ot/estados" onClick={this.hideSidebar} />
          </SidebarCategory>

          <SidebarCategory title="Clientes" icon="users">
            <SidebarLink title="Lista de Clientes" route="/clientes" onClick={this.hideSidebar} />
            <SidebarLink title="Condiciones Fiscales" route="/fiscal" onClick={this.hideSidebar} />
          </SidebarCategory>

          <SidebarCategory title="Productos" icon="star-empty">
            <SidebarLink title="Tipos de Producto" route="/productos/tipos" onClick={this.hideSidebar} />
            <SidebarLink title="Marcas" route="/marcas" onClick={this.hideSidebar} />
            <SidebarLink title="Modelos" route="/modelos" onClick={this.hideSidebar} />
          </SidebarCategory>

          <SidebarLink title="Empleados" icon="briefcase" route="/empleados" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Salir" icon="exit" route="/log_in" />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;