import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import EmployeeListView from '../../../employee-list-view/employee-list-view'
import CustomertListView from '../../../customer-list-view/customer-list-view'
import OtListView from '../../../ot-list-view/ot-list-view'
import OtPdfViewer from '../../../ot-list-view/components/ot-pdf-viewer'
import IncidentListView from '../../../incident-list-view/incident-list-view'
import ProductTypesListView from '../../../product-type-list-view/product-type-list-view'
import otCustomerTypeListView from '../../../ot-customer-type-list-view/ot-customer-type-list-view';
import FiscalListView from '../../../fiscal-condition-view/fiscal-list-view'
import BrandListView from '../../../brand-list-view/brand-list-view'
import ModelListView from '../../../model-list-view/model-list-view'
import OTStatesListView from '../../../ot-states-list-view/ot-states-list-view'
import MotiveListView from '../../../motive-list-view/motive-list-view';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/ots" component={OtListView} />
      <Route path="/empleados" component={EmployeeListView} />
      <Route path="/sucesos/:otId" component={IncidentListView} />
      <Route path="/clientes" exact component={CustomertListView} />
      <Route path="/productos/tipos" component={ProductTypesListView} />
      <Route path="/modelos" component={ModelListView} />
      <Route path="/marcas" component={BrandListView} />
      <Route path="/fiscal" component={FiscalListView} />
      <Route path="/ot/motivos" component={MotiveListView} />
      <Route path="/ot/estados" component={OTStatesListView} />
      <Route path="/pdf_ot" component={OtPdfViewer} />
      <Route path="/clientes/tipos" component={otCustomerTypeListView} />
    </div>
  </div>
);
