var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React from 'react';
import { reduxForm } from 'redux-form';
import MaterialTable from "material-table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PrintIconPdf from '@material-ui/icons/Print';
import { Redirect } from 'react-router';
import { tableIcons } from "../../shared/TableIcons";
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import { Chip, TablePagination } from '@material-ui/core';
import OtForm from './ot-form';
function NotifyError() {
    toast.error("Hubo un error en el servidor");
}
var otStateColorDict = {
    "En taller": "#0288D1",
    "Finalizado": "#F44336",
    "Pendiente": "#263238",
    "Con pedido de repuestos": "#607D8B",
    "Con pedido de cambio": "#43A047",
    "Visita a domicilio": "#EC407A",
};
var fields = [
    'id',
    'beginDate',
    'customer.fullName',
    'customerType',
    'customer.fiscalCondition',
    'budget',
    'employees[0].fullName',
    'state',
];
var selectedOt, selectedCustomer;
var OTListView = /** @class */ (function (_super) {
    __extends(OTListView, _super);
    function OTListView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isLoading: true,
            ots: [],
            count: 0,
            firstLoad: true,
            page: 1,
            pageSize: 15,
            search: '',
            sortField: '',
            sortType: '',
            otId: 0,
            isModalOpen: false,
            navigateIncidents: false,
            navigatePdf: false,
            delayTimer: 0,
            customers: []
        };
        _this.openModal = _this.openModal.bind(_this);
        _this.closeModal = _this.closeModal.bind(_this);
        _this.handleSearchChange = _this.handleSearchChange.bind(_this);
        return _this;
    }
    OTListView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                Promise.all([this.updateTable(), this.getCustomers()]).then(function (res) {
                    _this.setState({
                        firstLoad: false,
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    OTListView.prototype.openModal = function () {
        this.setState({
            isModalOpen: true
        });
    };
    ;
    OTListView.prototype.closeModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            isModalOpen: false,
                            otId: 0,
                        });
                        return [4 /*yield*/, this.updateTable()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OTListView.prototype.getCustomers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/customer/name")
                            .then(function (res) {
                            _this.setState({
                                customers: res.data.customers.map(function (customer) {
                                    return {
                                        fullName: customer.fullName,
                                        id: customer.id,
                                    };
                                })
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OTListView.prototype.updateTable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var otEndpoint, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        otEndpoint = (process.env.REACT_APP_SERVER_URL + "/ot")
                            .concat("?take=" + this.state.pageSize, "&skip=" + this.state.pageSize * (this.state.page - 1), "&sortField=" + this.state.sortField, "&sortType=" + this.state.sortType, "&search=" + this.state.search);
                        return [4 /*yield*/, axios.get(otEndpoint)
                                .then(function (res) {
                                _this.setState({
                                    ots: res.data.ots,
                                    count: res.data.count,
                                    isLoading: false
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        NotifyError();
                        console.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OTListView.prototype.handleChangePage = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({
                    page: page,
                    isLoading: true
                });
                return [2 /*return*/];
            });
        });
    };
    OTListView.prototype.handleChangeRowPerPage = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({
                    pageSize: parseInt(value),
                    page: 1,
                    isLoading: true
                });
                return [2 /*return*/];
            });
        });
    };
    OTListView.prototype.handleOrderChange = function (column) {
        return __awaiter(this, void 0, void 0, function () {
            var type;
            return __generator(this, function (_a) {
                type = 'ASC';
                if (this.state.sortField == fields[column]) {
                    type = this.state.sortType == 'ASC' ? 'DESC' : 'ASC';
                }
                this.setState({
                    sortField: fields[column],
                    sortType: type,
                    isLoading: true
                });
                return [2 /*return*/];
            });
        });
    };
    OTListView.prototype.handleSearchChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                window.clearTimeout(this.state.delayTimer);
                this.setState({
                    delayTimer: window.setTimeout(function () {
                        _this.setState({
                            search: event,
                            page: 1,
                            isLoading: true,
                        }, _this.updateTable);
                    }, 300)
                });
                return [2 /*return*/];
            });
        });
    };
    OTListView.prototype.render = function () {
        var _this = this;
        if (this.state.navigateIncidents) {
            return React.createElement(Redirect, { push: true, to: '/sucesos/' + this.state.otId });
        }
        return (React.createElement("div", { style: { padding: "0px 20px 20px 20px" } },
            React.createElement(MaterialTable, { isLoading: this.state.isLoading, data: this.state.ots, icons: tableIcons, title: "\u00D3rdenes de Trabajo", options: { pageSize: this.state.pageSize, actionsColumnIndex: -1, sorting: true, pageSizeOptions: [15, 30, 50] }, columns: [
                    { title: '#', field: fields[0], cellStyle: { width: 5, maxWidth: 5 }, headerStyle: { width: 5, maxWidth: 5 }, sorting: true, editable: "never" },
                    {
                        title: 'Fecha de ingreso', field: fields[1],
                        render: function (rowData) {
                            var _a, _b;
                            return Boolean((_a = rowData === null || rowData === void 0 ? void 0 : rowData.beginDate) === null || _a === void 0 ? void 0 : _a.split('T')[0]) ? (_b = rowData === null || rowData === void 0 ? void 0 : rowData.beginDate) === null || _b === void 0 ? void 0 : _b.split('T')[0] : "N/A";
                        }
                    },
                    {
                        title: 'Cliente', field: fields[2],
                        render: function (rowData) { var _a; return (_a = rowData.customer) === null || _a === void 0 ? void 0 : _a.fullName; }
                    },
                    {
                        title: 'Tipo de cliente', field: fields[3],
                        render: function (rowData) { return rowData.customerType; }
                    },
                    {
                        title: 'Condición fiscal', field: fields[4],
                        render: function (rowData) {
                            var _a, _b, _c, _d;
                            return Boolean((_b = (_a = rowData.customer) === null || _a === void 0 ? void 0 : _a.fiscalCondition) === null || _b === void 0 ? void 0 : _b.name) ? (_d = (_c = rowData.customer) === null || _c === void 0 ? void 0 : _c.fiscalCondition) === null || _d === void 0 ? void 0 : _d.name : "N/A";
                        }
                    },
                    {
                        title: 'Presupuesto', field: fields[5], cellStyle: { width: 40, maxWidth: 40 }, headerStyle: { width: 40, maxWidth: 40 },
                        render: function (RowData) { return RowData.budget.toLocaleString('es-AR', {
                            style: 'currency',
                            currency: 'ARS',
                        }); }
                    },
                    {
                        title: 'Responsable', field: fields[6], cellStyle: { width: 40, maxWidth: 40 }, headerStyle: { width: 40, maxWidth: 40 },
                        render: function (rowData) { var _a, _b; return Boolean((_a = rowData.employees[0]) === null || _a === void 0 ? void 0 : _a.fullName) ? (_b = rowData.employees[0]) === null || _b === void 0 ? void 0 : _b.fullName : "N/A"; }
                    },
                    {
                        title: 'Estado', field: fields[7], cellStyle: { width: 160, maxWidth: 160 }, headerStyle: { width: 150, maxWidth: 150 },
                        render: function (rowData) { return React.createElement(Chip, { label: rowData.state, color: otStateColorDict[rowData.state], style: { marginRight: 5, width: 150, backgroundColor: otStateColorDict[rowData.state], color: "white" } }); }
                    },
                ], detailPanel: function (rowData) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                    return (React.createElement("div", { style: { padding: "20px 0px 20px 20px" } },
                        React.createElement("h4", { style: { marginBottom: "10px" } }, "Producto"),
                        React.createElement("h5", null,
                            "N\u00FAmero de Serie: ", (_a = rowData.product) === null || _a === void 0 ? void 0 :
                            _a.serialNumber),
                        React.createElement("h5", null,
                            "Fecha de Compra: ",
                            rowData.product.purchaseDate ? (_c = (_b = rowData.product) === null || _b === void 0 ? void 0 : _b.purchaseDate) === null || _c === void 0 ? void 0 : _c.split('T')[0] : 'N/A'),
                        React.createElement("h5", null,
                            "Estado: ", (_d = rowData.product) === null || _d === void 0 ? void 0 :
                            _d.state),
                        React.createElement("h5", null,
                            "Marca: ",
                            ((_g = (_f = (_e = rowData.product) === null || _e === void 0 ? void 0 : _e.model) === null || _f === void 0 ? void 0 : _f.brand) === null || _g === void 0 ? void 0 : _g.name) || 'Sin marca'),
                        React.createElement("h5", null,
                            "Modelo: ",
                            ((_j = (_h = rowData.product) === null || _h === void 0 ? void 0 : _h.model) === null || _j === void 0 ? void 0 : _j.name) || 'Sin modelo'),
                        React.createElement("h5", { style: { marginBottom: "10px" } },
                            "Tipo de Producto: ", (_l = (_k = rowData.product) === null || _k === void 0 ? void 0 : _k.type) === null || _l === void 0 ? void 0 :
                            _l.name),
                        React.createElement("h4", { style: { marginBottom: "10px" } }, "Orden de trabajo"),
                        React.createElement("h5", null,
                            "Direcci\u00F3n: ",
                            rowData.address ? rowData.address : 'N/A'),
                        React.createElement("h5", null,
                            "C\u00F3digo de autorizaci\u00F3n: ",
                            rowData.authorizationCode ? rowData.authorizationCode : 'N/A'),
                        React.createElement("h5", null,
                            "Descripci\u00F3n de falla: ",
                            rowData.failureDescription),
                        React.createElement("h5", null,
                            "Accesorios recibidos: ",
                            rowData.recievedParts ? rowData.recievedParts : 'N/A'),
                        React.createElement("h5", null,
                            "Fecha de salida: ",
                            rowData.endDate ? rowData.endDate.split('T')[0] : 'N/A'),
                        React.createElement("h5", null,
                            "Diagn\u00F3stico: ",
                            rowData.diagnostic && rowData.diagnostic.description ? rowData.diagnostic.description : 'N/A'),
                        React.createElement("h5", null,
                            "Motivo: ",
                            rowData.motive ? rowData.motive : 'N/A')));
                }, actions: [
                    {
                        icon: AddBox,
                        onClick: function () {
                            _this.setState({
                                otId: 0,
                            });
                            _this.openModal();
                        },
                        isFreeAction: true,
                        tooltip: 'Nueva Órden de Trabajo',
                    },
                    {
                        icon: Edit,
                        onClick: function (event, rowData) {
                            _this.setState({
                                otId: rowData.id,
                            });
                            _this.openModal();
                        },
                        tooltip: "Editar",
                    },
                    {
                        icon: LibraryBooksIcon,
                        onClick: function (event, rowData) { return _this.setState({
                            navigateIncidents: true,
                            otId: rowData.id
                        }); },
                        tooltip: "Ver Sucesos",
                    },
                    {
                        icon: PrintIconPdf,
                        onClick: function (event, rowData) { return __awaiter(_this, void 0, void 0, function () {
                            var selectedCustomerId;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        selectedOt = this.state.ots.find(function (item) {
                                            return item.id == rowData.id;
                                        });
                                        selectedCustomerId = selectedOt.customer.id;
                                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/customer/" + selectedCustomerId)
                                                .then(function (res) {
                                                selectedCustomer = res.data;
                                                localStorage.setItem("ot", JSON.stringify(selectedOt));
                                                localStorage.setItem("customer", JSON.stringify(selectedCustomer));
                                                _this.props.history.push({
                                                    pathname: '/pdf_ot/'
                                                });
                                            }).catch(function (err) {
                                                console.log(err);
                                                NotifyError();
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); },
                        tooltip: "Ver PDF",
                    }
                ], onOrderChange: function (column) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.handleOrderChange(column)];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, this.updateTable()];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, localization: {
                    body: { deleteTooltip: 'Borrar', emptyDataSourceMessage: "No existen registros para esta tabla", editRow: { deleteText: '¿Está seguro de eliminar este elemento?' } },
                    header: { actions: "Acciones" },
                    toolbar: { searchPlaceholder: 'Buscar', searchTooltip: '' }
                }, components: {
                    Pagination: function (props) { return (React.createElement(TablePagination, __assign({}, props, { rowsPerPage: _this.state.pageSize, count: _this.state.count, page: _this.state.firstLoad
                            ? _this.state.page
                            : _this.state.page - 1, onChangePage: function (event, page) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.handleChangePage(page + 1)];
                                    case 1:
                                        _a.sent();
                                        return [4 /*yield*/, this.updateTable()];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, onChangeRowsPerPage: function (event) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        props.onChangeRowsPerPage(event);
                                        return [4 /*yield*/, this.handleChangeRowPerPage(event.target.value)];
                                    case 1:
                                        _a.sent();
                                        return [4 /*yield*/, this.updateTable()];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } }))); },
                }, onSearchChange: function (event) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.handleSearchChange(event)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }),
            this.state.isModalOpen ? React.createElement(OtForm, { isModalOpen: this.state.isModalOpen, onClose: this.closeModal, otId: this.state.otId, customers: this.state.customers }) : null,
            React.createElement(ToastContainer, null)));
    };
    return OTListView;
}(React.Component));
export default reduxForm({ form: 'vertical_form_validation' })(OTListView);
