var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React from 'react';
import MaterialTable from "material-table";
import { reduxForm } from 'redux-form';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { tableIcons } from "../../shared/TableIcons";
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import ReactLoading from 'react-loading';
import EmployeeForm from './employee-form';
function NotifyError() {
    toast.error("Hubo un error en el servidor");
}
var EmployeeListView = /** @class */ (function (_super) {
    __extends(EmployeeListView, _super);
    function EmployeeListView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            employees: [],
            employeeId: 0,
            isLoading: true,
            isModalOpen: false,
        };
        _this.openModal = _this.openModal.bind(_this);
        _this.closeModal = _this.closeModal.bind(_this);
        return _this;
    }
    EmployeeListView.prototype.closeModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            isModalOpen: false,
                            employeeId: 0,
                        });
                        return [4 /*yield*/, this.updateTable()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeListView.prototype.openModal = function () {
        this.setState({
            isModalOpen: true
        });
    };
    ;
    EmployeeListView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateTable()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeListView.prototype.updateTable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/employee/")
                                .then(function (res) {
                                var employeeList = res.data;
                                _this.setState({ employees: employeeList });
                            })];
                    case 1:
                        _a.sent();
                        this.setState({ isLoading: false });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        throw new Error(err_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeListView.prototype.deleteEmployee = function (employeeId) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.delete(process.env.REACT_APP_SERVER_URL + "/employee/" + employeeId, {})
                                .then(function (response) {
                                console.log(response);
                            })
                                .catch(function (error) {
                                console.log(error);
                            })];
                    case 1:
                        _a.sent();
                        this.setState({
                            employeeId: 0,
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        NotifyError();
                        console.error(err_2);
                        throw new Error(err_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeListView.prototype.render = function () {
        var _this = this;
        return (this.state.isLoading ? React.createElement("div", { style: {
                position: 'absolute', left: '55%', top: '50%',
                transform: 'translate(-50%, -50%)'
            } },
            " ",
            React.createElement(ReactLoading, { type: "spinningBubbles", color: "#70bbfd", height: 80, width: 80 }),
            " ") :
            React.createElement("div", { style: { padding: "0px 20px 20px 20px" } },
                React.createElement(MaterialTable, { icons: tableIcons, title: "Empleados", options: { pageSize: 15, actionsColumnIndex: -1, sorting: true, pageSizeOptions: [15, 30, 50] }, columns: [
                        { title: '#', field: 'id', cellStyle: { width: 20, maxWidth: 20 }, headerStyle: { width: 25, maxWidth: 25 }, sorting: true, defaultSort: "asc", editable: "never" },
                        { title: 'Nombre completo', field: 'fullName' },
                        { title: 'Dni', field: 'dni' },
                        { title: 'Correo', field: 'email' },
                        { title: 'Tel/Cel', field: 'phone' },
                        { title: '¿Es externo?', field: 'outsourced', render: function (rowData) { return !rowData.outsourced ? 'No' : 'Si'; } },
                    ], data: this.state.employees, actions: [
                        {
                            icon: AddBox,
                            onClick: function (event, rowData) {
                                _this.openModal();
                            },
                            isFreeAction: true,
                            tooltip: 'Nuevo Empleado',
                        },
                        {
                            icon: Edit,
                            onClick: function (event, rowData) {
                                _this.state.employeeId = rowData.id;
                                _this.openModal();
                            },
                            tooltip: 'Editar',
                        }
                    ], localization: { body: {
                            deleteTooltip: 'Borrar',
                            emptyDataSourceMessage: "No existen registros para esta tabla",
                            editRow: {
                                deleteText: '¿Está seguro de eliminar este elemento?'
                            },
                        },
                        header: { actions: "Acciones" },
                        toolbar: { searchPlaceholder: 'Buscar', searchTooltip: '' }
                    }, editable: {
                        onRowDelete: function (oldData) {
                            return new Promise(function (resolve) {
                                setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                    var _this = this;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                resolve();
                                                return [4 /*yield*/, this.deleteEmployee(oldData.id)];
                                            case 1:
                                                _a.sent();
                                                this.setState(function (prevState) {
                                                    var data = _this.state.employees;
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return __assign(__assign({}, prevState), { data: data });
                                                });
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, 600);
                            });
                        },
                    } }),
                React.createElement(ToastContainer, null),
                this.state.isModalOpen ? React.createElement(EmployeeForm, { isModalOpen: this.state.isModalOpen, onClose: this.closeModal, employeeId: this.state.employeeId }) : ''));
    };
    return EmployeeListView;
}(React.Component));
export default reduxForm({ form: 'vertical_form_validation' })(EmployeeListView);
