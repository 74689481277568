import React from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {
  DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown,
} from 'reactstrap';
import TopbarNavLink from './TopbarNavLink';
import TopbarNavCategory from './TopbarNavCategory';

const TopbarNavOtherPages = () => (
  <UncontrolledDropdown className="topbar__nav-dropdown">
    <DropdownToggle className="topbar__nav-dropdown-toggle">
      Other pages <DownIcon />
    </DropdownToggle>
    <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
      <DropdownItem>
        <TopbarNavCategory title="Forms" icon="file-add">
          <TopbarNavLink title="Basic Form" route="/forms/basic_form" />
          <TopbarNavLink title="Check Form Controls" route="/forms/check_form_controls" />
          <TopbarNavLink title="File Upload" route="/forms/file_upload" />
          <TopbarNavLink title="Floating Labels Form" route="/forms/floating_labels_form" />
          <TopbarNavLink title="Form Dropzone" route="/forms/form_dropzone" />
          <TopbarNavLink title="Form Layouts" route="/forms/form_layouts" />
          <TopbarNavLink title="Form Picker" route="/forms/form_picker" />
          <TopbarNavLink title="Form Validation" route="/forms/form_validation" />
          <TopbarNavLink title="Mask Form" route="/forms/mask_form" />
          <TopbarNavLink title="Material Form" route="/forms/material_form" />
          <TopbarNavLink title="Wizard Form" route="/forms/wizard_form" />
        </TopbarNavCategory>
      </DropdownItem>
      <DropdownItem>
        <TopbarNavCategory title="Tables" icon="list">
          <TopbarNavLink title="Basic tables" route="/tables/basic_tables" />
          <TopbarNavLink title="Data table" route="/tables/data_table" />
          <TopbarNavLink title="Editable table" route="/tables/editable_table" />
          <TopbarNavLink title="Material table" route="/tables/material_table" />
        </TopbarNavCategory>
      </DropdownItem>
    
      <DropdownItem>
        <TopbarNavCategory title="Account" icon="user">
          <TopbarNavLink title="Email Confirmation" route="/account/email_confirmation" />
          <TopbarNavLink title="Lock Screen" route="/lock_screen" />
          <TopbarNavLink title="Log In" route="/log_in" />
          <TopbarNavLink title="Log In Photo" route="/log_in_photo" />
          <TopbarNavLink title="Profile" route="/account/profile" />
          <TopbarNavLink title="Register" route="/register" />
          <TopbarNavLink title="Register Photo" route="/register_photo" />
        </TopbarNavCategory>
      </DropdownItem>
      <DropdownItem>
        <TopbarNavCategory title="Default Pages" icon="file-empty">
          <TopbarNavLink title="404" route="/404" />
          <TopbarNavLink title="Calendar" route="/default_pages/calendar" />
          <TopbarNavLink title="FAQs" route="/default_pages/faq" />
          <TopbarNavLink title="Gallery" route="/default_pages/gallery" />
          <TopbarNavLink title="Invoice template" route="/default_pages/invoice_template" />
          <TopbarNavLink title="Pricing Cards" route="/default_pages/pricing_cards" />
          <TopbarNavLink title="Project Summary" route="/default_pages/project_summary" />
          <TopbarNavLink title="Search Results" route="/default_pages/search_results" />
          <TopbarNavLink title="Text Editor" route="/default_pages/text_editor" />
        </TopbarNavCategory>
      </DropdownItem>
      <DropdownItem>
        <TopbarNavLink title="Log Out" icon="exit" route="/log_in" />
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default TopbarNavOtherPages;
