var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React from 'react';
import { reduxForm } from 'redux-form';
import MaterialTable from "material-table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tableIcons } from "../../shared/TableIcons";
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import ReactLoading from 'react-loading';
import ProductTypeForm from './product-type-form';
function NotifyError() {
    toast.error("Hubo un error en el servidor");
}
var ProductTypeListView = /** @class */ (function (_super) {
    __extends(ProductTypeListView, _super);
    function ProductTypeListView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isLoading: true,
            productTypes: [],
            isModalOpen: false,
            productTypeId: 0,
        };
        _this.openModal = _this.openModal.bind(_this);
        _this.closeModal = _this.closeModal.bind(_this);
        return _this;
    }
    ProductTypeListView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateTable()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductTypeListView.prototype.updateTable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(process.env.REACT_APP_SERVER_URL + "/product/types").then(function (res) {
                                var productTypes = res.data;
                                _this.setState({ productTypes: productTypes });
                            })];
                    case 1:
                        _a.sent();
                        this.setState({ isLoading: false });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        NotifyError();
                        console.error(err_1);
                        throw new Error(err_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductTypeListView.prototype.closeModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            isModalOpen: false
                        });
                        return [4 /*yield*/, this.updateTable()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductTypeListView.prototype.openModal = function () {
        this.setState({
            isModalOpen: true
        });
    };
    ;
    ProductTypeListView.prototype.render = function () {
        var _this = this;
        return (this.state.isLoading ? React.createElement("div", { style: {
                position: 'absolute', left: '55%', top: '50%',
                transform: 'translate(-50%, -50%)'
            } },
            " ",
            React.createElement(ReactLoading, { type: "spinningBubbles", color: "#70bbfd", height: 80, width: 80 }),
            " ") :
            React.createElement("div", { style: { padding: "0px 20px 20px 20px" } },
                React.createElement(MaterialTable, { icons: tableIcons, title: 'Tipos de Productos', options: { pageSize: 15, actionsColumnIndex: -1, sorting: true, pageSizeOptions: [15, 30, 50] }, columns: [
                        { title: '#', field: 'id', cellStyle: { width: 20, maxWidth: 20 }, headerStyle: { width: 25, maxWidth: 25 }, sorting: true, defaultSort: "asc", editable: "never" },
                        { title: 'Nombre', field: 'name' },
                    ], data: this.state.productTypes, actions: [
                        {
                            icon: AddBox,
                            onClick: function (event, rowData) {
                                _this.setState({
                                    productTypeId: 0,
                                });
                                _this.openModal();
                            },
                            isFreeAction: true,
                            tooltip: 'Nuevo Tipo de Producto',
                        },
                        {
                            icon: Edit,
                            onClick: function (event, rowData) {
                                _this.setState({
                                    productTypeId: rowData.id,
                                    isModalOpen: true,
                                });
                            },
                            tooltip: 'Editar',
                        }
                    ], localization: { body: { deleteTooltip: 'Borrar', emptyDataSourceMessage: "No existen registros para esta tabla", editRow: { deleteText: '¿Está seguro de eliminar este elemento?' } },
                        header: { actions: "Acciones" },
                        toolbar: { searchPlaceholder: 'Buscar', searchTooltip: '' }
                    }, editable: {
                        onRowDelete: function (oldData) {
                            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                var err_2;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, axios.delete(process.env.REACT_APP_SERVER_URL + "/product/types/" + oldData.id)];
                                        case 1:
                                            _a.sent();
                                            this.updateTable();
                                            resolve();
                                            return [3 /*break*/, 3];
                                        case 2:
                                            err_2 = _a.sent();
                                            NotifyError();
                                            console.log(err_2);
                                            reject();
                                            return [3 /*break*/, 3];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); });
                        },
                    } }),
                this.state.isModalOpen ? React.createElement(ProductTypeForm, { isModalOpen: this.state.isModalOpen, productTypeId: this.state.productTypeId, onClose: this.closeModal }) : '',
                React.createElement(ToastContainer, null)));
    };
    return ProductTypeListView;
}(React.Component));
export default reduxForm({ form: 'vertical_form_validation' })(ProductTypeListView);
