import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

class TopbarProfile extends PureComponent {
  static propTypes = {
    user: UserProps.isRequired,
  }


  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logout = () => {
    localStorage.removeItem('easydev');
  }

  render() {
    const { user } = this.props;
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle} style={{ verticalAlign: 'middle' }}>
          <p className="topbar__avatar-name" style={{ margin: 'auto' }}>
            {user}
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">         
            <TopbarMenuLink
              title="Salir"
              icon="exit"
              path="/log_in"
              onClick={this.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default TopbarProfile;
