import React from 'react';
import PropTypes from 'prop-types';
import withAuthFirebase from '../auth/withAuthFirebase';
import LogInForm from './LogInForm';

const LoginCard = ({ changeIsOpenModalFireBase }) => {
  return (
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Bienvenido a 
              <span className="account__logo-accent"> Azul Refrigeración</span>
          </h3>
        </div>
        <LogInForm
          form="log_in_form"
        />
      </div>
    </div>
  );
};

LoginCard.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LoginCard);
